import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";
import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  updateDoc,
  where,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  app,
  db,
  auth,
  usercollection,
  eventcollection,
  taskcollection,
  deparmentcollection,
} from "../../../FirebaseConfig";
import { useForm } from "react-hook-form";

const EditDepartment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [event, setEvent] = useState([]);
  const [row, setRow] = useState([]);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      department_name: "",
      phone: "pending",
    },
  });

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };
  const onSubmit = async (inputdata) => {
    const docRef = doc(db, deparmentcollection, params.id);
    updateDoc(docRef, inputdata)
      .then((docRef) => {
        successToast("Congratulations! Record updated successfully");
        navigate(-1);
      })
      .catch((error) => {
        errorToast("Oops, something went wrong!");
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, deparmentcollection, params.id);
      const docSnap = await getDoc(docRef);
      const getdata = docSnap.data();
      setRow(getdata);
      setValue("department_name", getdata?.department_name);
      setValue("phone", getdata?.phone);
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Edit Task Details</h5>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary add_user_btn_bar"
                >
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  &nbsp;Back
                </button>
              </div>

              <form
                method="post"
                onSubmit={handleSubmit(onSubmit, onErrors)}
                autocomplete="off"
              >
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="form-label">Department Name</label>
                      <input
                        name="department_name"
                        {...register("department_name", {
                          required: "Department name",
                        })}
                        className="form-control"
                        placeholder="Department Name"
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="form-label">Department Phone</label>
                      <input
                        name="phone"
                        {...register("phone", {
                          required: "Department phone",
                        })}
                        className="form-control"
                        placeholder="Department Phone"
                      />
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      className="btn btn-primary me-2"
                      type="submit"
                      disabled={disabled}
                    >
                      {" "}
                      {disabled == false ? "Save Changes" : "Updating..."}{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDepartment;
