import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../assest/admin_assest/img/logo/logo.jpeg";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
} from "../../functions/Function";
import { getuser } from "../../functions/User";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  addDoc,
  getDocs,
  limit,
  DocumentData,
  where,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
  doc,
} from "firebase/firestore";
import { app, db, auth } from "../../FirebaseConfig";
const findUser = async (uid = "") => {
  let user;
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    user = doc.data();
  });
  return user;
};
const Login = (props) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    signInWithEmailAndPassword(auth, inputs.email, inputs.password)
      .then((userCredential) => {
        setDisabled(false);
        const user = userCredential.user;
        let finduser = getuser(user.uid);
        finduser.then((res) => {
          let department_id = res?.department_id;
          let userDetails = {
            id: user.uid,
            docid: res.docid,
            role: res.role,
            name: res.name,
            email: res.email,
            department_id: department_id,
            profile: res.profile,
          };
          let userdata = {
            token: user.accessToken,
            user: userDetails,
          };
          localStorage.setItem("user", JSON.stringify(userdata));
          props.updateToken(user.accessToken);
          props.setIsUserLoggedin(true);
          successToast("Successfully LoggedIn.", "top-right", "2000");
          navigate("/admin/dashboard");
        });
      })
      .catch((error) => {
        setDisabled(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        console.log(error);
        switch (error.code) {
          case "auth/invalid-email":
            errorToast(
              "Please enter valid email or password",
              "top-right",
              "8000"
            );
            break;
          case "auth/invalid-email":
            errorToast("Please enter a valid email", "top-right", "8000");
            break;
          case "auth/missing-password":
            errorToast("Please enter a valid password", "top-right", "8000");
            break;
          case "auth/wrong-password":
            errorToast("Please enter a valid password", "top-right", "8000");
            break;
          case "auth/weak-password ":
            errorToast(error.message, "top-right", "8000");
            break;

          case "user-not-found":
            //returnAuth = messageUserNotFound;
            break;
        }
      });
  };

  return (
    <>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mb-2 text-center">
                      <img src={logo1} className="logo_bar_team" />
                    </h4>
                    <form method="POST" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Email or Username</label>

                        <input
                          type="text"
                          name="email"
                          value={inputs.email || ""}
                          onChange={handleChange}
                          placeholder="Enter your email or username"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <label className="form-label">Password</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="password"
                            name="password"
                            value={inputs.password || ""}
                            onChange={handleChange}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                          disabled={disabled}
                        >
                          {" "}
                          {disabled == false ? "Sign in" : "Signin..."}{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
