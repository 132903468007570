import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import usericon from '../../../assest/admin_assest/img/user.png';
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, usercollection } from "../../../FirebaseConfig";
import { MyThreeDots } from "../../../functions/Function";
const Viewserver= () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "users", params.id);
        const docSnap = await getDoc(docRef);
        setUser(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card_wredb">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">
                  Server Details
                </h5>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-primary add_user_btn_bar"
                >
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  &nbsp;Back
                </button>
              </div>
              <div class="card-body pt-0">
                {loader == true && MyThreeDots()}
                {loader == false && (
                   <div className="row">
                   <div className="col-sm-12 col-md-3">
                     {user?.profile ? <img src={user.profile} className='view-thumb-pic' />
                       :
                           <img src={usericon} className='view-thumb-pic' />
                       }
                   </div>
                   <div className="col-sm-12 col-md-9">
                       <div className="infodetailsfield">
                           <label className="fieldLabel">Name</label>
                           <label className="fieldvalue">{user.name}</label>
                       </div>

                       <div className="infodetailsfield">
                           <label className="fieldLabel">Phone</label>
                           <label className="fieldvalue">{user.phone}</label>
                       </div>


                       <div className="infodetailsfield">
                           <label className="fieldLabel">Email</label>
                           <label className="fieldvalue">{user.email}</label>
                       </div>

                       <div className="infodetailsfield">
                           <label className="fieldLabel">Status</label>
                           <label className="fieldvalue">{user.status == "1" && (
                                     <span className="badge bg-label-success me-1">
                                       Active
                                     </span>
                                   )}
                                   {user.status == "" && (
                                     <span className="badge bg-label-danger me-1">
                                       Inactive
                                     </span>
                                   )}</label>
                       </div>
                   </div>
             </div>    
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewserver;
