import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getDocs,
  get,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
  deparmentUsers,
} from "../../../FirebaseConfig";
import {
  getLoggedInuserRole,
  loggedInCustomerDetails,
} from "../../../functions/Function";
import { CSVLink } from "react-csv";

const Cook = (props) => {
  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Age", key: "age" },
  ];

  const [mydata, setmydata] = useState([
    {
      firstName: "Warren",
      lastName: "Morrow",
      email: "sokyt@mailinator.com",
      age: "36",
    },
    {
      firstName: "Gwendolyn",
      lastName: "Galloway",
      email: "weciz@mailinator.com",
      age: "76",
    },
    {
      firstName: "Astra",
      lastName: "Wyatt",
      email: "quvyn@mailinator.com",
      age: "57",
    },
    {
      firstName: "Jasmine",
      lastName: "Wong",
      email: "toxazoc@mailinator.com",
      age: "42",
    },
    {
      firstName: "Brooke",
      lastName: "Mcconnell",
      email: "vyry@mailinator.com",
      age: "56",
    },
    {
      firstName: "Christen",
      lastName: "Haney",
      email: "pagevolal@mailinator.com",
      age: "23",
    },
    {
      firstName: "Tate",
      lastName: "Vega",
      email: "dycubo@mailinator.com",
      age: "87",
    },
    {
      firstName: "Amber",
      lastName: "Brady",
      email: "vyconixy@mailinator.com",
      age: "78",
    },
    {
      firstName: "Philip",
      lastName: "Whitfield",
      email: "velyfi@mailinator.com",
      age: "22",
    },
    {
      firstName: "Kitra",
      lastName: "Hammond",
      email: "fiwiloqu@mailinator.com",
      age: "35",
    },
    {
      firstName: "Charity",
      lastName: "Mathews",
      email: "fubigonero@mailinator.com",
      age: "63",
    },
  ]);

  const [csvReport, setcsvReport] = useState({
    data: mydata,
    headers: headers,
    filename: "Clue_Mediator_Report.csv",
  });

  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [joinedData, setJoinedData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch users collection
    const fetchUsers = async () => {
      let q;
      if (getLoggedInuserRole() == "manager") {
        q = query(
          collection(db, usercollection),
          where("role", "==", "cook"),
          where("department_id", "==", loggedInCustomerDetails("department_id"))
        );
      } else {
        q = query(collection(db, usercollection), where("role", "==", "cook"));
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
        performLeftJoin();
      });
    };

    // Fetch departments collection
    const fetchDepartments = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({ ...doc.data() }));
        setDepartments(result);
        performLeftJoin();
      });
    };

    fetchUsers();
    fetchDepartments();
  }, []);

  useEffect(() => {
    performLeftJoin();
  }, [users, departments]);

  // Perform left join operation
  const performLeftJoin = () => {
    const joinedDataWithUsersAndDepartments = users
      .map((join) => {
        const department = departments.find(
          (dept) => dept.id == join.department_id
        );
        return { docid: join.docid, ...join, department };
      })
      .filter((element) => element.department !== undefined);
    setData(joinedDataWithUsersAndDepartments);
  };
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Cooks List</h5>
                {/* <CSVLink {...csvReport}>Export to CSV</CSVLink> */}
                {getLoggedInuserRole() == "manager" && (
                  <Link to="add" className="btn btn-primary add_user_btn_bar">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                    Cook
                  </Link>
                )}
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>
                        <th>
                          <i className="fa fa-image"></i>
                        </th>
                        <th className="td_head_name">Name</th>
                        <th>Email</th>
                        <th>Phone No.</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {data.length > 0 ? (
                        data.map((row, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {row?.profile ? (
                                <img src={row.profile} className="thumb-pic" />
                              ) : (
                                <img src={usericon} className="thumb-pic" />
                              )}
                            </td>
                            <td>
                              <div>
                                <b>{row.name}</b>
                              </div>
                              <small>
                                <i>{row?.department?.department_name}</i>
                              </small>
                            </td>
                            <td>{row.email}</td>
                            <td>{row.phone}</td>
                            <td>
                              {row.status == "1" && (
                                <span className="badge bg-label-success me-1">
                                  Active
                                </span>
                              )}
                              {row.status == "0" && (
                                <span className="badge bg-label-danger me-1">
                                  Inactive
                                </span>
                              )}
                            </td>

                            <td>
                              <div>
                                <Link
                                  to={`view/${row.docid}`}
                                  className="btn btn-icon btn-primary ball_icon Edit_user_user_list"
                                >
                                  <span className="tf-icons tf-icons fa fa-eye"></span>
                                </Link>

                                {getLoggedInuserRole() == "manager" && (
                                  <>
                                    <Link
                                      to={`edit/${row.docid}`}
                                      className="btn btn-icon btn-primary ball_icon"
                                    >
                                      <span className="tf-icons fa fa-pencil-square"></span>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <p className="text-center m-2"> Data Not Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cook;
