import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import des1 from "../../../assest/admin_assest/img/illustrations/man-with-laptop-light.png";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
  getCountFromServer,
} from "firebase/firestore";
import {
  app,
  db,
  usercollection,
  taskcollection,
  eventcollection,
  deparmentcollection,
} from "../../../FirebaseConfig";

import {
  getLoggedInuserRole,
  loggedInUserDetails,
} from "../../../functions/Function";

const Dashboard = () => {
  const [counts, setCount] = useState({
    total_event: 0,
    total_server: 0,
    total_supervisor: 0,
    total_manager: 0,
    total_department: 0,
  });
  useEffect(() => {
    const getTotalEventCount = async () => {
      const coll = collection(db, "events");
      const snapshot = await getCountFromServer(coll);
      setCount((prevCounts) => ({
        ...prevCounts,
        total_event: snapshot.data().count,
      }));
    };

    const getTotalDepartmentsCount = async () => {
      const coll = collection(db, "department");
      const snapshot = await getCountFromServer(coll);
      setCount((prevCounts) => ({
        ...prevCounts,
        total_department: snapshot.data().count,
      }));
    };

    const getTotalServerCount = async () => {
      const coll = collection(db, "users");
      const q = query(coll, where("role", "==", "server"));
      const snapshot = await getCountFromServer(q);
      setCount((prevCounts) => ({
        ...prevCounts,
        total_server: snapshot.data().count,
      }));
    };

    const getTotalPorterCount = async () => {
      const coll = collection(db, "users");
      const q = query(coll, where("role", "==", "porter"));
      const snapshot = await getCountFromServer(q);
      setCount((prevCounts) => ({
        ...prevCounts,
        total_porter: snapshot.data().count,
      }));
    };

    const getTotalSupervisorCount = async () => {
      const coll = collection(db, "users");
      const q = query(coll, where("role", "==", "supervisor"));
      const snapshot = await getCountFromServer(q);
      setCount((prevCounts) => ({
        ...prevCounts,
        total_supervisor: snapshot.data().count,
      }));
    };

    const getTotalManagerCount = async () => {
      const coll = collection(db, "users");
      const q = query(coll, where("role", "==", "manager"));
      const snapshot = await getCountFromServer(q);

      setCount((prevCounts) => ({
        ...prevCounts,
        total_manager: snapshot.data().count,
      }));
    };

    getTotalEventCount();
    getTotalDepartmentsCount();
    getTotalSupervisorCount();
    getTotalServerCount();
    getTotalPorterCount();
    getTotalManagerCount();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div
            className={`${
              getLoggedInuserRole() == "administrator"
                ? "col-lg-9 "
                : "col-lg-12"
            } mb-4 order-0`}
          >
            <div className="card">
              <div className="d-flex align-items-end row">
                <div className="col-sm-7">
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      Congratulations {loggedInUserDetails("name")}
                    </h5>
                    <p className="mb-4">
                      You can See Summery of Entire System.
                    </p>
                  </div>
                </div>
                <div className="col-sm-5 text-center text-sm-left">
                  <div className="card-body pb-0 px-0 px-md-4">
                    <img
                      src={des1}
                      height="140"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {getLoggedInuserRole() == "administrator" && (
            <div className="col-lg-3 col-md-12 col-6 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar custom-avatar flex-shrink-0">
                      <i
                        className="fa fa-calendar-o menu-icon tf-icons"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn p-0"
                        type="button"
                        id="cardOpt3"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>

                      <div
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="cardOpt3"
                      >
                        <Link className="dropdown-item" to="/admin/event/">
                          View More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <span className="fw-semibold d-block mb-1">Total Events</span>
                  <h3 className="card-title mb-2">{counts?.total_event}</h3>
                  <small className="text-success fw-semibold"></small>
                </div>
              </div>
            </div>
          )}

          {getLoggedInuserRole() == "administrator" && (
            <>
              {/* <div className="col-lg-3 col-md-12 col-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i
                          className="fa fa-calendar-o menu-icon tf-icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt6"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt6"
                        >
                          <Link className="dropdown-item" to="/admin/event/">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span>Event</span>
                    <h3 className="card-title text-nowrap mb-1">5</h3>
                    <small className="text-success fw-semibold">
                      completed
                    </small>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-12 col-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i
                          className="fa fa-calendar-o menu-icon tf-icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt6"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt6"
                        >
                          <Link className="dropdown-item" to="/admin/event/">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span>Events</span>
                    <h3 className="card-title text-nowrap mb-1">4</h3>
                    <small className="text-info fw-semibold">
                      <span className="">Current Running</span>
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i
                          className="fa fa-calendar-o menu-icon tf-icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt6"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt6"
                        >
                          <Link className="dropdown-item" to="/admin/event/">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span>Event</span>
                    <h3 className="card-title text-nowrap mb-1">4</h3>
                    <small className="text-info fw-semibold">Upcoming</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i
                          className="fa fa-calendar-o menu-icon tf-icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt6"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="cardOpt6"
                        >
                          <Link className="dropdown-item" to="/admin/event/">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <span>Event</span>
                    <h3 className="card-title text-nowrap mb-1">2</h3>
                    <small className="text-danger fw-semibold">canceled</small>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-12 col-md-4 order-1">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar custom-avatar flex-shrink-0">
                            <i
                              className="fa-solid fa-table-cells-large menu-icon "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt3"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt3"
                            >
                              <Link
                                className="dropdown-item"
                                to="/admin/department/"
                              >
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span className="fw-semibold d-block mb-1">
                          Total Department
                        </span>
                        <h3 className="card-title mb-2">
                          {counts?.total_department}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar custom-avatar flex-shrink-0">
                            <i
                              className="fa-solid fa-people-group menu-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link
                                className="dropdown-item"
                                to="/admin/department/"
                              >
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Total Manager</span>
                        <h3 className="card-title text-nowrap mb-1">
                          {counts?.total_manager}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar custom-avatar flex-shrink-0">
                            <i
                              className="fa-solid fa-users-line menu-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link
                                className="dropdown-item"
                                to="/admin/supervisor/"
                              >
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Total Supervisor</span>
                        <h3 className="card-title text-nowrap mb-1">
                          {counts?.total_supervisor}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar custom-avatar flex-shrink-0">
                            <i
                              className="fa-solid fa-circle-user menu-icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link
                                className="dropdown-item"
                                to="/admin/server/"
                              >
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Total Server</span>
                        <h3 className="card-title text-nowrap mb-1">
                          {counts?.total_server}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-12 col-md-4 order-1">
                            <div className="row">
                              <div className="col-lg-3 col-md-12 col-6 mb-4">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                      <div className="avatar flex-shrink-0">
                                      <i className="fa fa-user menu-icon tf-icons icon_user_color" aria-hidden="true"></i>
                                      </div>
                                      <div className="dropdown">
                                        <button
                                          className="btn p-0"
                                          type="button"
                                          id="cardOpt3"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                                          <Link className="dropdown-item" to="/admin/user/">View More</Link>
            
                                        </div>
                                      </div>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Total User</span>
                                    <h3 className="card-title mb-2">100</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> <span className='All_time_user'>All Time</span></small>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12 col-6 mb-4">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                      <div className="avatar flex-shrink-0">
                                        <i className="fa fa-user menu-icon tf-icons icon_user_color" aria-hidden="true"></i>
                                      </div>
                                      <div className="dropdown">
                                        <button
                                          className="btn p-0"
                                          type="button"
                                          id="cardOpt6"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt6">
                                          <Link className="dropdown-item" to="/admin/user/">View More</Link>
              
                                        </div>
                                      </div>
                                    </div>
                                    <span>Active User</span>
                                    <h3 className="card-title text-nowrap mb-1">75</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> Active</small>
                                  </div>
                                </div>
                              </div>
          
                              <div className="col-lg-3 col-md-12 col-6 mb-4">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                      <div className="avatar flex-shrink-0">
                                      <i className="fa fa-user menu-icon tf-icons icon_user_color" aria-hidden="true"></i>
                                      </div>
                                      <div className="dropdown">
                                        <button
                                          className="btn p-0"
                                          type="button"
                                          id="cardOpt6"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt6">
                                          <Link className="dropdown-item" to="/admin/user/">View More</Link>
                            
                                        </div>
                                      </div>
                                    </div>
                                    <span>Inactive User</span>
                                    <h3 className="card-title text-nowrap mb-1">25</h3>
                                    <small className="text-success fw-semibold">
                                    <span className='inctive_red'>Inactive</span>
                                    </small>
                                  </div>
                                </div>
                              </div>        
                                <div className="col-lg-3 col-md-12 col-6 mb-4">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                      <div className="avatar flex-shrink-0">
                                      <i className="fa fa-user menu-icon tf-icons icon_user_color" aria-hidden="true"></i>
                                      </div>
                                      <div className="dropdown">
                                        <button
                                          className="btn p-0"
                                          type="button"
                                          id="cardOpt6"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt6">
                                          <Link className="dropdown-item" to="#">View More</Link>
                                
                                        </div>
                                      </div>
                                    </div>
                                    <span>Sales</span>
                                    <h3 className="card-title text-nowrap mb-1">$4,679</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> +28.42%</small>
                                  </div>
                                </div>
                              </div>
          
                            </div>
                          </div> */}
            </>
          )}

          {getLoggedInuserRole() == "department" && (
            <>
              <div className="col-lg-12 col-md-4 order-1">
                {/* <h1 className='fb_dashboard_department'>{loggedInUserDetails('name')} Dashboard</h1> */}
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar flex-shrink-0">
                            <i
                              className="fa fa-user menu-icon tf-icons icon_user_color"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt3"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt3"
                            >
                              <Link className="dropdown-item" to="#">
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span className="fw-semibold d-block mb-1">
                          Total Task
                        </span>
                        <h3 className="card-title mb-2">100</h3>
                        <small className="text-success fw-semibold">
                          <i className="bx bx-up-arrow-alt"></i>{" "}
                          <span className="All_time_user">All Time</span>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar flex-shrink-0">
                            <i
                              className="fa fa-user menu-icon tf-icons icon_user_color"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link className="dropdown-item" to="#">
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Canceled Task</span>
                        <h3 className="card-title text-nowrap mb-1">5</h3>
                        <small className="text-danger fw-semibold">
                          <i className="bx bx-up-arrow-alt"></i> Canceled
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar flex-shrink-0">
                            <i
                              className="fa fa-user menu-icon tf-icons icon_user_color"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link className="dropdown-item" to="#">
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Supervisor Task</span>
                        <h3 className="card-title text-nowrap mb-1">25</h3>
                        <small className="text-success fw-semibold">
                          <small className="text-success fw-semibold">
                            <i className="bx bx-up-arrow-alt"></i>Task
                          </small>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-6 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title d-flex align-items-start justify-content-between">
                          <div className="avatar flex-shrink-0">
                            <i
                              className="fa fa-user menu-icon tf-icons icon_user_color"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="dropdown">
                            <button
                              className="btn p-0"
                              type="button"
                              id="cardOpt6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="cardOpt6"
                            >
                              <Link className="dropdown-item" to="#">
                                View More
                              </Link>
                            </div>
                          </div>
                        </div>
                        <span>Server Task</span>
                        <h3 className="card-title text-nowrap mb-1">10</h3>
                        <small className="text-success fw-semibold">
                          <i className="bx bx-up-arrow-alt"></i>Task
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
