import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getDocs,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  doc,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db, eventcollection } from "../../../FirebaseConfig";
import {
  timeshowAMPM,
  changeDateFotmate,
  successToast,
  errorToast,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  departments,
  createNotification,
} from "../../../functions/Notification";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CSVLink } from "react-csv";

const Event = () => {
  const [data, setData] = useState([]);
  const [filterData, setfilterData] = useState("All");
  const headers = [
    { label: "EVENT NAME", key: "name" },
    { label: "EVENT DATE", key: "event_date" },
    { label: "EVENT START TIME", key: "event_start_time" },
    { label: "EVENT END TIME", key: "event_end_time" },
    { label: "NUMBER OF PEOPLE", key: "no_of_people" },
    { label: "RES START TIME ", key: "res_start_time" },
    { label: "RES END TIME", key: "res_end_time" },
    { label: "RESERVATION ID", key: "reservation_id" },
    { label: "EVENT CONSULTANT", key: "event_consultant" },
    { label: "ADDITIONAL COMMENTS", key: "additional_comments" },
    { label: "LOCATION", key: "location" },
    { label: "STATUS", key: "status" },
  ];
  const [csvdata, setCsvdata] = useState([]);
  const [csvReport, setcsvReport] = useState({
    data: data,
    headers: headers,
    filename: "events.csv",
  });

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, eventcollection, id));
          Swal.fire("Deleted!", "The record has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    });
  };

  const handleChangeStatus = async (id, getstatus, rowdata) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to change status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const docRef = doc(db, eventcollection, id);
          updateDoc(docRef, { status: getstatus })
            .then((docRef) => {
              let actionsting = "";
              if (getstatus == "completed") {
                actionsting = "event_update_status_completed_by_Admin";
              } else if (getstatus == "started") {
                actionsting = "event_update_status_started_by_Admin";
              } else {
                actionsting = "event_update_status_notstarted_by_Admin";
              }

              const promises = [];
              const departmentslist = departments().then((list) => {
                list.forEach((department) => {
                  const promise = createNotification({
                    to_id: department.department_id.toString(),
                    type: "event",
                    action: actionsting,
                    event_name: rowdata.name,
                    event_status: getstatus,
                  });

                  promises.push(promise);
                });
              });

              Promise.all(promises).then(() => {
                successToast(
                  "Congratulations! Event status updated successfully"
                );
              });
            })
            .catch((error) => {
              errorToast("Oops, something went wrong!");
              console.log(error);
            });

          //await deleteDoc(doc(db, eventcollection, id));
          // Swal.fire("Deleted!", "The record has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    });
  };

  function BasicButtonExample(props) {
    //console.log(id);
    let varientclass;
    let label;
    if (props.status == "started") {
      varientclass = "info";
      label = "Event Started";
    } else if (props.status == "completed") {
      varientclass = "success";
      label = "Completed";
    } else {
      varientclass = "danger";
      label = "Not Started";
    }
    return (
      <DropdownButton
        variant={varientclass}
        id="dropdown-basic-button"
        title={label}
      >
        <Dropdown.Item
          onClick={() =>
            handleChangeStatus(props.id, "notstarted", props.rowdata)
          }
        >
          Not Started
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleChangeStatus(props.id, "started", props.rowdata)}
        >
          Event Started
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            handleChangeStatus(props.id, "completed", props.rowdata)
          }
        >
          Event Completed
        </Dropdown.Item>
      </DropdownButton>
    );
  }
  const handleClickFilter = (status) => {
    setfilterData(status);
  };
  useEffect(() => {
    const fetchData = async () => {
      let q;
      if (filterData == "completed") {
        q = query(
          collection(db, eventcollection),
          where("status", "==", filterData)
        );
      } else if (filterData == "started") {
        q = query(
          collection(db, eventcollection),
          where("status", "==", filterData)
        );
      } else if (filterData == "notstarted") {
        q = query(
          collection(db, eventcollection),
          where("status", "==", filterData)
        );
      } else {
        q = query(collection(db, eventcollection));
      }
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(result);

        setcsvReport({ ...csvReport, data: result });
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, [filterData]);
  return (
    <>
      {console.log(data)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header departprt_head">
                  Event List{" "}
                  <div className="inline-block ml-4">
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        {filterData == "completed" && "Completed"}
                        {filterData == "started" && "Started"}
                        {filterData == "notstarted" && "Notstarted"}
                        {filterData == "All" && "All"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleClickFilter("All")}>
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleClickFilter("completed")}
                        >
                          COMPLETED
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleClickFilter("started")}
                        >
                          STARTED
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleClickFilter("notstarted")}
                        >
                          NOTSTARTED
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </h5>

                <span>
                  <CSVLink className="btn btn-dark m-2" {...csvReport}>
                    Export to CSV
                  </CSVLink>
                  <Link to="add" className="btn btn-primary add_user_btn_bar">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                    Event
                  </Link>
                </span>
              </div>

              <div className="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>
                          <div className="w-150">
                            Date
                            <br />
                            Start- End Time
                          </div>
                        </th>
                        <th className="td_head_name_department">Event Name</th>
                        <th>No. Of People</th>
                        <th>Status</th>
                        <th>
                          <div className="w-150">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {data.length > 0 ? (
                        data.map((row, i) => (
                          <tr key={row.id}>
                            <td className="bgeventdatecol">
                              <div className="eventdate">
                                <span className="d-block edate">
                                  <i
                                    className="fa fa-calendar-o menu-icon tf-icons"
                                    aria-hidden="true"
                                  ></i>
                                  {changeDateFotmate(
                                    row.event_date,
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                                <span className="d-block eventtiime">
                                  <i className="fa-regular fa-clock"></i>{" "}
                                  {timeshowAMPM(row.event_start_time)} -{" "}
                                  {timeshowAMPM(row.event_end_time)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <strong>{row.name}</strong>
                            </td>

                            <td>{row.no_of_people}</td>
                            <td>
                              <BasicButtonExample
                                id={row.id}
                                status={row.status}
                                rowdata={row}
                              />
                            </td>
                            <td>
                              <div>
                                <Link
                                  to={`view/${row.id}`}
                                  className="btn btn-icon btn-primary ball_icon"
                                >
                                  <span className="tf-icons tf-icons fa fa-eye"></span>
                                </Link>
                                <Link
                                  to={`edit/${row.id}`}
                                  className="btn btn-icon btn-primary ball_icon"
                                >
                                  <span className="tf-icons fa fa-pencil-square"></span>
                                </Link>

                                <button
                                  type="button"
                                  className="btn btn-icon btn-danger"
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <span className="tf-icons fa fa-trash"></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            <h6 className="m-4">Event not available</h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
