import React from 'react'
import { Link } from 'react-router-dom';
const ViewServerTeam = () => {
  return (
    <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="col-lg-12 mb-4 order-0">
                        <div className="card card_wredb">
                            <div className='user_name_add'>
                                <h5 className="card-header card_hed_user">Server Team Details</h5>

                                <Link to="/admin/serverteam" className='user_list_add'>
                                    <button type="button" className="btn btn-primary add_user_btn_bar">
                                        <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                    </button>
                                </Link>
                            </div>
                            <div className="table-responsive text-nowrap">
                                <table className="table table-bordered">
                                    <thead className=''>
                                        <tr>
                                            <th className="">Name</th>
                                            <td>Albert Cook</td>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th>Status</th>
                                            <td>Active</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ViewServerTeam;
