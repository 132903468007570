import React , {useEffect , useState} from 'react'
import { Link } from 'react-router-dom'
import { getusers } from "../../../functions/User";
import { getDocs, collection , onSnapshot , query , doc , where} from "firebase/firestore"; 
import { db , usercollection } from "../../../FirebaseConfig"
const User = () => {
    //https://softauthor.com/category/firebase/
    const [data , setData] = useState([])
     useEffect(() => {
        const fetchData = async () => {
          const q = query(collection(db, usercollection));
          const unsubscribe = onSnapshot(q, (snapshot) => {
            const result = snapshot.docs.map((doc) => {
                if(doc?.data()?.role == 'administrator')
                {
                    return  { docid: doc.id, ...doc.data() }
                }else
                {
                    return null;
                }
            }).filter((result) => result  !== null );
            setData(result);
          });
          return () => {
            unsubscribe();
          };
        };
        fetchData();
      }, []);

    return (
        <>
            
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="col-lg-12 mb-4 order-0">
                        <div className="card">
                            <div className='user_name_add'>
                                <h5 className="card-header card_hed_user">Users List</h5>
                            </div>
                            <div class="card-body pt-0">
                            <div className="table-responsive custom">
                                <table className="table table-md table-bordered">
                                    <thead className='table-dark'>
                                        <tr>
                                            <th className="stickycol">SR.No</th> 
                                            <th className="stickycol1" ><div className='w-200'>Name</div> </th>
                                            <th><div className='w-200'>Phone No.</div></th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th className="stickycol3"><div className='w-120'>Actions</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { data.length > 0 && data.map((row , i) => (
                                        <tr>
                                           <td>{i+1}</td>
                                            <td>
                                                <div><strong>{row.name}</strong></div>
                                                <div><small><i>{row.email}</i></small></div>

                                            </td>
                                            <td>{row.phone}</td>
                                            <td><span class="badge bg-label-success me-1">{row.role}</span></td>
                                            <td><span className="badge bg-label-success me-1">Active</span></td>

                                            <td>
                                                <div>
                                                    <Link to={`edit/${row.docid}`} className='Edit_user_user_list'>
                                                        <button type="button" class="btn btn-icon btn-primary ball_icon">
                                                            <span class="tf-icons fa fa-pencil-square"></span>
                                                        </button>
                                                    </Link>
                                                    </div>

                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User;
