import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import {
  getDocs,
  get,
  collection,
  onSnapshot,
  updateDoc,
  query,
  doc,
  where,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
  deparmentUsers,
  eventcollection,
  checklistassigncollection,
} from "../../../FirebaseConfig";

import {
  changeDateFotmate,
  loggedInUserDetails,
  successToast,
  errorToast,
} from "../../../functions/Function";

const Departmentchecklist = () => {
  const [assignedchecklist, setassignedchecklist] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [events, setEvents] = useState([]);
  const [joinedData, setJoinedData] = useState([]);
  const [data, setData] = useState([]);

  const handleChangeStatus = async (row, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to change status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const docRef = doc(db, checklistassigncollection, row.docid);
          updateDoc(docRef, { status: status })
            .then((docRef) => {
              successToast("Congratulations! Status updated successfully");
            })
            .catch((error) => {
              errorToast("Oops, something went wrong!");
              console.log(error);
            });

          //await deleteDoc(doc(db, eventcollection, id));
          // Swal.fire("Deleted!", "The record has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    });
  };

  useEffect(() => {
    // Fetch assigned checklist  collection
    const fetchassignedchecklist = async () => {
      const q = query(
        collection(db, checklistassigncollection),
        where("department_id", "==", loggedInUserDetails("department_id"))
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setassignedchecklist(result);
        performLeftJoin();
      });
    };

    // Fetch departments collection
    const fetchDepartments = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({ ...doc.data() }));
        setDepartments(result);
        performLeftJoin();
      });
    };

    const fetchEvents = async () => {
      const q = query(collection(db, eventcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setEvents(result);
        performLeftJoin();
      });
    };

    const fetchUsers = async () => {
      const q = query(collection(db, usercollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
        performLeftJoin();
      });
    };

    fetchUsers();
    fetchEvents();
    fetchDepartments();
    fetchassignedchecklist();
  }, []);

  useEffect(() => {
    performLeftJoin();
  }, [assignedchecklist, departments, users, events]);

  // Perform left join operation
  const performLeftJoin = () => {
    const joinedDataWithUsersAndDepartments = assignedchecklist
      .map((join) => {
        const department = departments.find(
          (dept) => dept.id == join.department_id
        );
        const user = users.find((userdata) => userdata.docid == join.to_id);
        const event = events.find(
          (eventdata) => eventdata.docid == join.event_id
        );
        return {
          docid: join.docid,
          ...join,
          department: department,
          user: user,
          event: event,
        };
      })
      .filter((element) => element.department !== undefined);
    setData(joinedDataWithUsersAndDepartments);
  };

  return (
    <>
      {console.log(data)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Checklist</h5>

                {/* <Link to="adduser" className='user_list_add'>
                                    <button type="button" class="btn btn-primary add_user_btn_bar">
                                        <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Add User
                                    </button>
                                </Link> */}
              </div>
              <div class="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>
                        <th className="">Name</th>
                        <th className="">Event</th>
                        <th>Department</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((row, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <div>
                                <b>{row?.user?.name}</b>{" "}
                                <span className="badge bg-label-dark me-1">
                                  {row?.user?.role}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <b>{row?.event.name}</b>
                              </div>
                            </td>
                            <td>{row?.department?.department_name}</td>

                            <td>
                              <div className="inline-block">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant={`${
                                      row.status == "completed"
                                        ? "success"
                                        : "danger"
                                    }`}
                                    id="dropdown-basic"
                                    className="btn-sm inline-block"
                                  >
                                    {row.status == "completed" && "Completed"}
                                    {row.status == "pending" && "Pending"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleChangeStatus(row, "completed")
                                      }
                                    >
                                      Completed
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleChangeStatus(row, "pending")
                                      }
                                    >
                                      Pending
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Departmentchecklist;
