import React, { useEffect, useState , useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where
} from 'firebase/firestore';
import { auth , app, db, usercollection } from '../../../FirebaseConfig';
import { getAuth , EmailAuthProvider  } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { useForm } from 'react-hook-form';
import {
  successToast,
  errorToast,
  MyThreeDots,
  formValidtionsMessages,
  loggedInUserDetails,
  updateSession
} from '../../../functions/Function';
import  { AppContext }  from "../../../App";

const Changepassword = () => {
    const AppContextData = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [edituser , setEdituser] = useState({})
    const { register, handleSubmit, formState: { errors } , setValue , watch } = useForm({
        defaultValues: { 
           old_password: "",
           new_password: "",
        }
       });
       
       const onErrors =  (errors) => {
            const errorLength = Object.keys(errors).length;
            if(errorLength > 0)
            {
                errorToast(formValidtionsMessages(errors) , 'top-right' , '8000'); 
            }
        };
          
        const onSubmit = async (data) => {
            try {
                
                const user = auth.currentUser;
                
                const credential = EmailAuthProvider.credential(
                  loggedInUserDetails('email'),
                  data.password
                );
                console.log(credential)

                await user.reauthenticateWithCredential(credential);
                await user.updatePassword(data.password);
                successToast('Congratulations! password updated successfully')
            } catch (error) {
                console.error(error);
                errorToast('Opps somthing wrong!');
              }

           
        }
        
       

    

  return (
    <>          
            
            <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <h5 className="card-header"></h5>
                            <form method='post'  onSubmit={handleSubmit(onSubmit , onErrors)}  autocomplete="off">
                            <div className="card-body">

                            <div className='row gy-3'>
                            <div className='col-lg-12'>
                                    <label  className="form-label">Old Password</label>
                                    <input
                                        type="Password"
                                       
                                        className="form-control"
                                        name='old_password'
                                        {...register('old_password', {
                                            required: 'Old password  is required',
                                        })}
                                        placeholder="Old Password"
                                       
                                    />
                                </div>
                                <div className='col-lg-12'>
                                    <label  className="form-label">New Password</label>
                                    <input
                                        type="Password"
                                        className="form-control"
                                        name='new_password'
                                        {...register('new_password', {
                                            required: 'New password  is required',
                                        })}
                                        placeholder="New Password"
                                       
                                    />
                                </div>
                              

                            </div>
    
                                    <div className="mt-4">
                                    <button type="submit" className="btn btn-primary me-2">Save changes</button>
                                    </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
    </>
  )
}

export default Changepassword;
