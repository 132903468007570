import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import department from "../../../assest/admin_assest/img/department.png";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
  orderBy,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
} from "../../../FirebaseConfig";
import { MyThreeDots } from "../../../functions/Function";
const ViewPorterMainManager = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  // const getuserData = async () => {
  //   let q;
  //   q = query(
  //     collection(db, usercollection),
  //     where("role", "==", "manager"),
  //     where("department_id", "==", data?.id)
  //     //   where("department_id", "==", "1003")
  //   );

  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     const result = snapshot.docs.map((doc) => ({
  //       docid: doc.id,
  //       ...doc.data(),
  //     }));

  //     setUsers(result);
  //     console.log("result");
  //     console.log(result);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // };

  const getuserData = async () => {
    console.log("ID" + data?.id);
    try {
      const q = query(
        collection(db, usercollection),
        where("role", "==", "manager"),
        where("department_id", "==", data?.id)
      );

      const querySnapshot = await getDocs(q);

      const mapPromises = querySnapshot.docs.map(async (doc) => {
        const userData = {
          docid: doc.id,
          ...doc.data(),
        };
        return userData;
      });

      Promise.all(mapPromises).then((result) => {
        setUsers(result);
        console.log(result);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const docRef = doc(db, deparmentcollection, params.id);
        const docSnap = await getDoc(docRef);
        setData(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      getuserData();
    }
  }, [data]);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card card_wredb">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">
                  Department Details
                </h5>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-primary add_user_btn_bar"
                >
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  &nbsp;Back
                </button>
              </div>
              <div className="card-body pt-0">
                {loader == true && MyThreeDots()}
                {loader == false && (
                  <div className="row">
                    <div className="col-sm-12 col-md-2">
                      {data?.profile ? (
                        <img src={data?.profile} className="view-thumb-pic" />
                      ) : (
                        <img src={department} className="view-thumb-pic" />
                      )}
                    </div>
                    <div className="col-sm-12 col-md-10">
                      <div className="infodetailsfield">
                        <label className="fieldLabel">Name :</label>
                        <label className="fieldvalue">
                          {data?.department_name}
                        </label>
                      </div>

                      <div className="infodetailsfield">
                        <label className="fieldLabel">Phone :</label>
                        <label className="fieldvalue">{data?.phone}</label>
                      </div>

                      <div className="infodetailsfield">
                        <label className="fieldLabel">Email :</label>
                        <label className="fieldvalue">{data?.email}</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {users.length > 0 && (
                <>
                  <div className="user_name_add">
                    <h5 className="card-header">Manager Information</h5>
                  </div>

                  {users.length > 0 &&
                    users.map((user, i) => (
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-sm-12 col-md-2">
                            {user?.profile ? (
                              <img
                                src={user.profile}
                                className="view-thumb-pic"
                              />
                            ) : (
                              <img src={usericon} className="view-thumb-pic" />
                            )}
                          </div>
                          <div className="col-sm-12 col-md-10">
                            <div className="infodetailsfield">
                              <label className="fieldLabel">
                                Manager Name :{" "}
                              </label>
                              <label className="fieldvalue">{user.name}</label>
                            </div>

                            <div className="infodetailsfield">
                              <label className="fieldLabel">
                                Manager Phone :{" "}
                              </label>
                              <label className="fieldvalue">{user.phone}</label>
                            </div>

                            <div className="infodetailsfield">
                              <label className="fieldLabel">
                                Manager Email :{" "}
                              </label>
                              <label className="fieldvalue">{user.email}</label>
                            </div>

                            <div className="infodetailsfield">
                              <label className="fieldLabel">
                                Manager Status :
                              </label>
                              <label className="fieldvalue">
                                {user.status == "1" && (
                                  <span className="badge bg-label-success me-1">
                                    Active
                                  </span>
                                )}
                                {user.status == "" && (
                                  <span className="badge bg-label-danger me-1">
                                    Inactive
                                  </span>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPorterMainManager;
