import Moment from "moment";
import {
  app,
  db,
  auth,
  usercollection,
  deparmentcollection,
  notificationscollection,
} from "../FirebaseConfig";
import {
  getFirestore,
  addDoc,
  getDocs,
  limit,
  DocumentData,
  where,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
  doc,
  serverTimestamp,
} from "firebase/firestore";

export const changeDateFotmate = (date = "", format = "") => {
  return Moment(date).format(format);
};

export const departmentNotifications = async () => {
  const querySnapshot = await getDocs(
    query(collection(db, "notifications"), where("department_id", "==", 1001))
  );

  let result = [];
  querySnapshot.forEach((res) => {
    result.push({ ...res.data() });
  });
  return { notifications: result };
};

export const AddEventTypeNotification = async (notificationData, type) => {
  try {
    const fetchDepartments = async () => {
      const q = query(collection(db, deparmentcollection));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        department_id: doc.data().id,
      }));
    };

    const departments = await fetchDepartments();

    if (departments.length > 0) {
      const dbRef = collection(db, "notifications");
      departments.forEach((data) => {
        addDoc(dbRef, {
          ...notificationData,
          to_id: data.department_id,
          is_read: true,
          created_date: Moment().format("L, h:mm:ss a"),
        });
      });
    }
  } catch (error) {
    console.error("Error fetching departments and adding documents:", error);
  }
};

export const taskCreatedForUsers = (data) => {
  return "";
};

export const departments = async () => {
  const departmentIds = [];

  try {
    const q = query(collection(db, deparmentcollection));
    const snapshot = await getDocs(q);

    snapshot.docs.forEach((doc) => {
      departmentIds.push({
        department_id: doc.data().id,
      });
    });

    return departmentIds;
  } catch (error) {
    console.error("Error fetching departments:", error);
    throw error;
  }
};

// Create a new notification for different events
export function createNotification(data) {
  const notificationData = {
    type: data?.type,
    to_id: data?.to_id,
    message: getNotificationMessage(data.action, data),
    createdAt: Moment().format("DD-MM-YYYY hh:mm A"),
    isRead: false,
    timestamp: serverTimestamp(),
  };
  return addDoc(collection(db, notificationscollection), notificationData);
}

// Helper function to get notification messages based on type and event data
function getNotificationMessage(type, data) {
  switch (type) {
    case "create_event":
      return `A ${data.event_name} new event has been created`;
    case "update_event":
      return `Event ${data.event_name} has been updated`;
    case "create_task":
      return `A new task has been created for ${data.event_name}.`;
    case "update_task_status":
      return `The status of the task for the ${data.event_name} event has been updated to ${data.task_status}`;
    case "update_task_status_completed_by_users":
      return `${data.user_name} has changed the status of the task to '${data.task_status}' for the '${data.event_name}' event.`;
    case "event_update_status_completed_by_Admin":
      return `The event ${data.event_name} is now ${data.event_status}.`;
    case "event_update_status_started_by_Admin":
      return `The event ${data.event_name} is now ${data.event_status}.`;
    case "event_update_status_notstarted_by_Admin":
      return `The event ${data.event_name} is now not started.`;
    default:
      return "New notification";
  }
}
