import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Scheduleaddmodel from "./Scheduleaddmodel";
import Moment from "moment";
import {
  getFirestore,
  addDoc,
  serverTimestamp,
  collection,
  doc,
  where,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInCustomerDetails,
  MyThreeDots,
} from "../../../functions/Function";

import {
  app,
  db,
  auth,
  usercollection,
  schaudelcollection,
} from "../../../FirebaseConfig";

const Scheduleadd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);

  const TimeStampToLocalDate = ({ seconds }) => {
    const date = new Date(seconds * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    // Get the local date components (day, month, year)
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return (
      <span>
        {day} {month} {year}
      </span>
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      let q;
      q = query(
        collection(db, schaudelcollection),
        where("user_id", "==", params.id),
        where("department_id", "==", loggedInCustomerDetails("department_id"))
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setData(result);
      });
    };
    fetchData();
  }, []);

  return (
    <>
      {console.log(data)}
      <Scheduleaddmodel
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={params.id}
      />
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Schedule Report</h5>
                <button
                  type="button"
                  className="btn btn-primary add_user_btn_bar"
                  onClick={() => setModalShow(true)}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  &nbsp;Add Schedule
                </button>
              </div>
              <div className="card-body">
                {data.length > 0 && (
                  <table className="table table-md table-bordered">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>From Time</th>
                        <th>To Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {console.log(data)}
                      {data.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <TimeStampToLocalDate seconds={row.date.seconds} />
                          </td>
                          <td>{row.start_time}</td>
                          <td>{row.end_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scheduleadd;
