import React from 'react'
import { Link } from 'react-router-dom'
const Adduser = () => {
    return (
        <>

            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <div className='user_name_add'>
                                    <h5 className="card-header card_hed_user">Add User</h5>
                                    <Link to="/admin/user" className='user_list_add'>
                                        <button type="button" class="btn btn-primary add_user_btn_bar">
                                            <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                        </button>
                                    </Link>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <label for="defaultFormControlInput" class="form-label">Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="defaultFormControlInput"
                                            placeholder="Enter Name"
                                            aria-describedby="defaultFormControlHelp"
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <label for="defaultFormControlInput" class="form-label">Email</label>
                                        <input
                                            type="email"
                                            class="form-control"
                                            id="defaultFormControlInput"
                                            placeholder="Email"
                                            aria-describedby="defaultFormControlHelp"
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <label for="defaultFormControlInput" class="form-label">Phone No.</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="defaultFormControlInput"
                                            placeholder="Phone Number"
                                            aria-describedby="defaultFormControlHelp"
                                        />
                                    </div>


                                    <div className='mt-4'>
                                        <label for="defaultFormControlInput" class="form-label">Password</label>
                                        <input
                                            type="Password"
                                            class="form-control"
                                            id="defaultFormControlInput"
                                            placeholder="Password"
                                            aria-describedby="defaultFormControlHelp"
                                        />
                                    </div>



                                    <div className='mt-4'>
                                        <label for="defaultFormControlInput" class="form-label">Conferm Password</label>
                                        <input
                                            type="Password"
                                            class="form-control"
                                            id="defaultFormControlInput"
                                            placeholder="Conferm Password"
                                            aria-describedby="defaultFormControlHelp"
                                        />
                                    </div>



                                    <div class="mt-4">
                                        <button type="submit" class="btn btn-primary me-2">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Adduser;
