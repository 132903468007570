import React, { useEffect, useState , useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where
} from 'firebase/firestore';
import { app, db, usercollection } from '../../../FirebaseConfig';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { useForm } from 'react-hook-form';
import {
  successToast,
  errorToast,
  MyThreeDots,
  formValidtionsMessages,
  loggedInUserDetails,
  updateSession
} from '../../../functions/Function';
import  { AppContext }  from "../../../App";
import Changepassword  from "./Changepassword";

const Profile = () => {
    const AppContextData = useContext(AppContext);
    const [click, setClick] = useState("profile");
    const activebox = (currentbox='') =>
     {
        setClick(currentbox);
     }
     const navigate = useNavigate();
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [edituser , setEdituser] = useState({})
    const { register, handleSubmit, formState: { errors } , setValue , watch } = useForm({
        defaultValues: { 
           name: "",
           email: "",
           phone: "",
           profile: "",
           status:""
        }
       });
       const handleFileChange =   async (e) => {
        console.log(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            const storage = getStorage(app);
            const storageRef = ref(storage, `uploads/${file.name}`);
            await uploadBytes(storageRef, file)
              .then((snapshot) => {
                 const downloadURL =  getDownloadURL(snapshot.ref);
                 downloadURL.then((data) =>{
                    updateSession('profile' ,data)
                    setValue("profile", data);
                })
            })
              .catch((error) => {
                // Handle error uploading the profile photo
              });
          }
        };
        const removeImg =   async ()=> {
            setValue("profile" , "")
        }
        
        const onErrors =  (errors) => {
            const errorLength = Object.keys(errors).length;
            if(errorLength > 0)
            {
                errorToast(formValidtionsMessages(errors) , 'top-right' , '8000'); 
            }
        };
          
        const onSubmit = async (data) => {
                const docRef = doc(db, usercollection, loggedInUserDetails('docid'));
                const updateData = {
                    name:data.name,
                    phone:data.phone,
                    profile:data.profile,
                };
                
                updateDoc(docRef, updateData)
                    .then(docRef => {
                        successToast('Congratulations! profile updated successfully')
                        AppContextData.reloadApp();
                    })
                    .catch(error => {
                        errorToast('Opps somthing wrong!');
                        console.log(error);
                    })
        }
        
        useEffect(() => {
        const getuserData = async() => 
        {
            try {
                const docRef = doc(db, usercollection, loggedInUserDetails('docid'));
                const docSnap = await getDoc(docRef);
                console.log(docSnap)
                const row = docSnap.data();
                console.log(row)
                setValue("name", row.name);
                setValue("email", row.email);
                setValue("phone", row.phone);
                setValue("profile", row.profile);
                setValue("status", row.status);
                setLoader(false)
            } catch(error) {
                console.log(error)
               
            }
        }
        getuserData();
    }, []);



  return (
    <>          
            
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                <div className='col-md-12'>
                <ul className="nav nav-pills flex-column flex-md-row mb-3 combo_bar_dom">
                    <li className="nav-item">
                      <Link className={`nav-link ${click == 'profile' && 'active'}`}><i className="bx bx-user me-1"></i> <span className={click=='profile'}  onClick={()=>activebox('profile')}>Account</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${click == 'chnagepass' && 'active'}`}><i className="bx bx-bell me-1"></i><span className={click=='chnagepass'}  onClick={()=>activebox('chnagepass')}>ChangePassword</span></Link>
                    </li>
                  </ul>
                </div>
                        {click=='profile' &&
                            <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                <div className='user_name_add'>
                                <h5 className="card-header">Profile Details</h5>
                            </div>
                                
                                {loader == true && MyThreeDots()}
                                {loader == false &&
                                 <form method='post'  onSubmit={handleSubmit(onSubmit , onErrors)}  autocomplete="off">
                                    <div className="card-body">

                                    <div className='row gy-3'>
                                    <div className='col-lg-12'>
                                            <label  className="form-label">Name</label>
                                            <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="Enter Name"
                                                        {...register('name', {
                                                            required: 'Name is required',
                                                            maxLength: {
                                                                value: 50,
                                                                message: 'Name should not exceed 50 characters'
                                                            }
                                                        })}
                                                    />
                                        </div>
                                        
                                        <div className='col-lg-6'>
                                            <label  className="form-label">Email</label>
                                            <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        {...register('email', {
                                                            required: 'Email is required',
                                                            pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                            }
                                                        })}
                                                        readOnly={true}
                                                        placeholder="Email"
                                                
                                                        
                                                    />
                                        </div>
                                        <div className='col-lg-6'>
                                            <label  className="form-label">Phone No.</label>
                                            <input
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Phone Number"
                                                        {...register('phone', {
                                                            required: 'Phone number is required',
                                                        })}
                                                    />
                                        </div>

                                        <div className='col-lg-12'>
                                            <label  className="form-label">Profile</label>
                                            {watch("profile") && 
                                                    <div className='profileboxViewer'>
                                                        <img src={watch("profile")} width={80}  className='mb-2' />
                                                        <i class="fa-regular fa-circle-xmark removeProfileImg" onClick={()=>removeImg()}></i>
                                                    </div>
                                                    
                                                    }


                                                      
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={handleFileChange}
                                                    />
                                        </div>

                                    </div>
            

                                


                        <div className="mt-4">
                            <button type="submit" className="btn btn-primary me-2">Save changes</button>
                            </div>
                                    </div>
                                </form>
                                }
                                </div>
                            </div>
                            </div>
                        }
                 
                        {click=='chnagepass' &&
                           <Changepassword />
                        }

                </div>
            </div>
    </>
  )
}

export default Profile;
