import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  orderBy,
  doc,
  where,
  serverTimestamp,
  firestoreTimestamp,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  app,
  db,
  auth,
  usercollection,
  eventcollection,
  taskcollection,
  schaudelcollection,
} from "../../../FirebaseConfig";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";
import {
  MyThreeDots,
  timeshowAMPM,
  changeDateFotmate,
} from "../../../functions/Function";

import { useForm } from "react-hook-form";
import { Watch } from "react-loader-spinner";

const Eventmodel = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [event, setEvent] = useState({});

  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "events", props.id);
        const docSnap = await getDoc(docRef);
        setEvent(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, [props.id]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Event Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loader === true && <MyThreeDots />}
        {loader === false && (
          <div className="row">
            <div className="col-sm-12 col-md-3 mb-4">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-arrow-right"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">Event Name</span>
                      <span className="eventboxcontent-data">{event.name}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 mb-4">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-regular fa-calendar"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">Event Date</span>
                      <span className="eventboxcontent-data">
                        {changeDateFotmate(event.event_date, "DD-MM-YYYY")}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 mb-4">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-clock"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Event Start Time
                      </span>
                      <span className="eventboxcontent-data">
                        {timeshowAMPM(event?.event_start_time)}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 mb-4">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-regular fa-clock"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Event End Time
                      </span>
                      <span className="eventboxcontent-data">
                        {timeshowAMPM(event?.event_end_time)}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-person"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Number Of People
                      </span>
                      <span className="eventboxcontent-data">
                        {event?.no_of_people}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-clock"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Res Start Time
                      </span>
                      <span className="eventboxcontent-data">
                        {timeshowAMPM(event?.res_start_time)}
                      </span>
                    </div>
                  </li>

                  {/* <li>
                    <div className="eventboxicon">
                      <i class="fa-regular fa-clock"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Res End Time
                      </span>
                      <span className="eventboxcontent-data">
                        {timeshowAMPM(event?.res_end_time)}
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">Location</span>
                      <span className="eventboxcontent-data">
                        {event?.location}
                      </span>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-regular fa-clock"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">
                        Res End Time
                      </span>
                      <span className="eventboxcontent-data">
                        {timeshowAMPM(event?.res_end_time)}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-3">
              <div className="eventboxleft">
                <ul className="eventboxleftlistitembox">
                  <li>
                    <div className="eventboxicon">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>

                    <div className="eventboxcontent">
                      <span className="eventboxcontent-label">Location</span>
                      <span className="eventboxcontent-data">
                        {event?.location}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Eventmodel;
