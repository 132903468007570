import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, deparmentcollection } from "../../../FirebaseConfig";

const Department = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(result);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setData(result);
      });
      return () => {
        unsubscribe1();
      };
    };
    fetchData1();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header departprt_head">Department List </h5>
              </div>
              <div class="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>
                        <th className="td_head_name_department">
                          Department Name
                        </th>
                        <th>Department Email</th>
                        <th>Department Phone No.</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {data.map((row, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <strong>{row.department_name}</strong>
                          </td>
                          <td>{row.email} </td>
                          <td>{row.phone}</td>

                          <td>
                            <div>
                              {/* <Link to="viewdepartment" className='Edit_user_user_list'>
                                                        <button type="button" className="btn btn-icon btn-primary  ball_icon">
                                                            <span className="tf-icons tf-icons fa fa-eye"></span>
                                                        </button>
                                                    </Link> */}

                              <Link
                                to={`viewdepartment/${row.docid}`}
                                className="btn btn-icon btn-primary ball_icon Edit_user_user_list"
                              >
                                <span className="tf-icons tf-icons fa fa-eye"></span>
                              </Link>

                              <Link
                                to={`editdepartment/${row.docid}`}
                                className="btn btn-icon btn-primary ball_icon Edit_user_user_list"
                              >
                                <span className="tf-icons fa fa-pencil-square"></span>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Department;
