import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, usercollection } from "../../../FirebaseConfig";
import { MyThreeDots } from "../../../functions/Function";

const Catering = (props) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, usercollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs
          .map((doc) => {
            if (doc?.data()?.role === "catering") {
              return { docid: doc.id, ...doc.data() };
            } else {
              return null;
            }
          })
          .filter((result) => result !== null);
        setData(result);
        setLoader(false);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Catering Managers</h5>
                <Link to="add" className="user_list_add">
                  <button
                    type="button"
                    className="btn btn-primary add_user_btn_bar"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                    Catering Manager
                  </button>
                </Link>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>
                        <th>
                          <i className="fa fa-image"></i>
                        </th>
                        <th className="td_head_name">Name</th>
                        <th>Phone No.</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {loader == true && (
                        <tr>
                          <td colSpan={6}>{loader == true && MyThreeDots()}</td>
                        </tr>
                      )}

                      {loader == false &&
                        data.length > 0 &&
                        data.map((row, i) => (
                          <tr key={row.docid}>
                            <td>{i + 1}</td>

                            <td>
                              {row?.profile ? (
                                <img src={row.profile} className="thumb-pic" />
                              ) : (
                                <img src={usericon} className="thumb-pic" />
                              )}
                            </td>
                            <td>
                              <div>
                                <strong>{row.name}</strong>
                              </div>
                              <div>
                                <small>
                                  <i>{row.email}</i>
                                </small>
                              </div>
                            </td>
                            <td>{row.phone}</td>
                            <td>
                              {row?.status == "1" && (
                                <span className="badge bg-label-success me-1">
                                  Active
                                </span>
                              )}

                              {row?.status == "0" && (
                                <span className="badge bg-label-danger me-1">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              <div>
                                <Link
                                  to={`edit/${row.docid}`}
                                  className="btn btn-icon btn-primary"
                                >
                                  <span className="tf-icons fa fa-pencil-square"></span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catering;
