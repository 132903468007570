import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, usercollection } from "../../../FirebaseConfig";
import {
  MyThreeDots,
  timeshowAMPM,
  changeDateFotmate,
} from "../../../functions/Function";
const Eventviewglobal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [row, setRow] = useState({});
  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "events", params.id);
        const docSnap = await getDoc(docRef);
        setRow(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);
  return (
    <>
      {console.log(row)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Event Details</h5>
                <button
                  onClick={() => navigate(-1)}
                  class="btn btn-primary add_user_btn_bar"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                </button>
              </div>
              <div class="card-body pt-0">
                {loader == true && MyThreeDots()}
                {loader == false && (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody className="table-border-bottom-0">
                        <tr>
                          <td colSpan={5} className="vieweventtitle">
                            <b>Event :</b> {row?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <b>Event Start</b>
                            </div>{" "}
                            {timeshowAMPM(row?.event_start_time)}
                          </td>
                          <td>
                            <div>
                              <b>Event End :</b>
                            </div>
                            {timeshowAMPM(row?.event_end_time)}
                          </td>
                          <td>
                            <div>
                              <b>Number Of People </b>
                            </div>
                            {row?.no_of_people}
                          </td>
                          <td>
                            <div>
                              <b>Res Start </b>
                            </div>{" "}
                            {timeshowAMPM(row?.res_start_time)}
                          </td>
                          <td>
                            <div>
                              <b>Res End </b>
                            </div>
                            {timeshowAMPM(row?.res_end_time)}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={5}>
                            <div>
                              {" "}
                              <b>Location </b>
                            </div>
                            <p> {row?.location}</p>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={1}>
                            <b>Status</b>
                          </td>
                          <td colSpan={4}>
                            <p>
                              <span class="badge bg-label-success me-1">
                                completed
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eventviewglobal;
