import React from 'react'
import { Link } from 'react-router-dom';
const Addtask = () => {
  return (
    <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <div className='user_name_add'>
                                    <h5 className="card-header card_hed_user">Add Task</h5>
                                    <Link to="/admin/task" className='user_list_add'>
                                        <button type="button" class="btn btn-primary add_user_btn_bar">
                                            <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                        </button>
                                    </Link>
                                </div>
                                <div class="card-body">
                                <div className='row'>
                                  <div className='col-lg-6'>
                                             <div className="">
                                                    <label for="largeSelect" className="form-label">Event List</label>
                                                       <select id="largeSelect" className="form-select form-select-lg form_selected_for_custom">
                                                        <option>Event Select</option>
                                                        <option value="1">Marriage party</option>
                                                        <option value="2">Birthday party</option>
                                                        <option value="3">Marriage anniversary</option>
                                                        <option value="3">Workplace Catering Manager</option>
                                                        <option value="3">New year Party</option>
                                                        <option value="3">Farewell Party</option>
                                                    </select>
                                                </div> 
                                            </div>

                                            <div className='col-lg-6'>
                                             <div className="">
                                                    <label for="largeSelect" className="form-label">Department Select</label>
                                                       <select id="largeSelect" className="form-select form-select-lg form_selected_for_custom">
                                                        <option>Department Select</option>
                                                        <option value="1">F&B Manager</option>
                                                        <option value="2">F&B Manager & Kitchen</option>
                                                        <option value="3">Exco Manager</option>
                                                        <option value="3">Workplace Catering Manager</option>
                                                        <option value="3">Liquor Manager</option>
                                                    </select>
                                                </div> 
                                            </div>

                                            <div className='col-lg-12 mt-4'>
                                                <label for="exampleFormControlTextarea1" className="form-label">Department task</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>

                                            <div className='col-lg-12 mt-4'>
                                             <div className="">
                                                    <label for="largeSelect" className="form-label">Status</label>
                                                       <select id="largeSelect" className="form-select form-select-lg form_selected_for_custom">
                                                        <option>Select Task</option>
                                                        <option value="1">Completed</option>
                                                        <option value="2">Cancled</option>
                                                        <option value="3">Pending</option>
                                                    </select>
                                                </div> 
                                            </div>

                                  </div>
                        
                                    <div class="mt-4 text-center">
                                      <button type="submit" class="btn btn-primary me-2">Create Task</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
    </>
  )
}

export default Addtask;
