import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getDocs,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  doc,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  db,
  eventcollection,
  eventservicescollection,
} from "../../../FirebaseConfig";
import {
  timeshowAMPM,
  changeDateFotmate,
  successToast,
  errorToast,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  departments,
  createNotification,
} from "../../../functions/Notification";

const Service = () => {
  const [data, setData] = useState([]);
  const [filterData, setfilterData] = useState("All");
  const [eventIds, setEventsIds] = useState([]);
  const [events, setEvents] = useState([]);

  const handleClickFilter = (status) => {
    setfilterData(status);
  };

  const handleChangeStatus = async (row, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to change status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const docRef = doc(db, "event_services", row.id);
          updateDoc(docRef, { status: status })
            .then((docRef) => {
              successToast(
                "Congratulations! Service status updated successfully"
              );
            })
            .catch((error) => {
              errorToast("Oops, something went wrong!");
              console.log(error);
            });

          //await deleteDoc(doc(db, eventcollection, id));
          // Swal.fire("Deleted!", "The record has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    });
  };

  const getEventDetails = (event_id = "") => {
    const event = events.find((event) => event.docid === event_id);
    return event ? event.name : null;
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const q = query(collection(db, eventcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setEvents(result);
      });
    };

    const fetchData = async () => {
      let q;
      if (filterData == "All") {
        q = query(
          collection(db, eventservicescollection),
          where(
            "department_id",
            "==",
            Number(loggedInUserDetails("department_id"))
          )
        );
      } else {
        q = query(
          collection(db, eventservicescollection),
          where(
            "department_id",
            "==",
            Number(loggedInUserDetails("department_id"))
          ),
          where("status", "==", filterData)
        );
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const storeEventsIDs = snapshot.docs.map((doc) => ({
          id: doc.data().event_id,
        }));

        console.log(result);
        setData(result);
        setEventsIds(storeEventsIDs);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchEvents();
    fetchData();
    //fetchEventData();
  }, [filterData]);
  return (
    <>
      {console.log(events)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="m-0">My Services</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-3 servicesfiilter">
            <div className="col-77">Filter</div>
            <div className="col-777 ">
              <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  {filterData}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleClickFilter("All")}>
                    All
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleClickFilter("ASSIGNED")}>
                    ASSIGNED
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleClickFilter("NOTASSIGNED")}
                  >
                    NOTASSIGNED
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {data.length > 0 ? (
            data.map((row, i) => (
              <>
                {console.log(row)}
                <div className="col-lg-12 mt-1" key={row.id}>
                  <div className="card mb-4 customcardbgs p-4">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-1">
                          <span className="sename">
                            <b>
                              <i className="fa-regular fa-calendar-days"></i>{" "}
                              Event :{" "}
                            </b>
                            {getEventDetails(row.event_id)}
                          </span>
                        </div>
                        <div className="mb-1">
                          {" "}
                          <span className="sename">
                            <b>
                              {" "}
                              <i className="fa-solid fa-table-cells-large"></i>{" "}
                              Service Type :{" "}
                            </b>{" "}
                            {row.type}
                          </span>
                        </div>
                        <div className="mb-1">
                          <span className="sename">
                            <b>
                              {" "}
                              <i className="fa-solid fa-utensils"></i> Serving
                              Items :{" "}
                            </b>{" "}
                            {row.item}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-1">
                          <span className="sename">
                            <b>
                              <i className="fa-regular fa-clock"></i> Serving
                              Time :{" "}
                            </b>{" "}
                            {row.serving_time}
                          </span>
                        </div>

                        <div className="mb-1">
                          <span className="sename">
                            <b>
                              <i className="fa-solid fa-square-poll-vertical"></i>{" "}
                              Items QTY:{" "}
                            </b>{" "}
                            {row.qty}
                          </span>
                        </div>
                        <div className="mb-1">
                          <span className="sename">
                            <b>
                              <i className="fa-solid fa-magnifying-glass"></i>{" "}
                              Status:{" "}
                            </b>{" "}
                          </span>
                          <div className="inline-block">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant={`${
                                  row.status == "NOTASSIGNED"
                                    ? "danger"
                                    : "dark"
                                }`}
                                id="dropdown-basic"
                                className="btn-sm inline-block"
                              >
                                {row.status}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleChangeStatus(row, "ASSIGNED")
                                  }
                                >
                                  ASSIGNED
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleChangeStatus(row, "NOTASSIGNED")
                                  }
                                >
                                  NOTASSIGNED
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <h6 className="text-center">No matching records found</h6>
          )}
        </div>
      </div>
    </>
  );
};

export default Service;
