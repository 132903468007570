import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  orderBy,
  doc,
  where,
  serverTimestamp,
  firestoreTimestamp,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  app,
  db,
  auth,
  usercollection,
  eventcollection,
  taskcollection,
  schaudelcollection,
} from "../../../FirebaseConfig";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";
import { MyThreeDots } from "../../../functions/Function";

import { useForm } from "react-hook-form";
import { Watch } from "react-loader-spinner";

const Schedule = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [taskdata, settaskdata] = useState([]);
  const [schaudeldata, setSchaudeldata] = useState([]);
  const [events, setEvents] = useState([
    // { title: "Meeting", start: new Date() },
    // { title: "Meeting2", start: new Date() },
  ]);

  // a custom render function
  function renderEventContent(eventInfo) {
    console.log("eventInfo");
    console.log(eventInfo?.event?._def.extendedProps?.start_time);
    return (
      <>
        <span
          class={`badge ${
            eventInfo?.event?._def.extendedProps?.is_status == "available"
              ? "bg-label-info"
              : "bg-label-danger"
          } me-1`}
        >
          {eventInfo?.event?._def.extendedProps?.start_time} to{" "}
          {eventInfo?.event?._def.extendedProps?.end_time} <br /> <br />{" "}
          {eventInfo?.event.title}
        </span>
        {/* <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <i>
          {eventInfo?.event?._def.extendedProps?.start_time} to{" "}
          {eventInfo?.event?._def.extendedProps?.end_time}
        </i> */}
      </>
    );
  }

  const fetchData = async () => {
    const q = query(
      collection(db, "tasks"),
      where("to_id", "==", props.id),
      orderBy("timestamp", "desc")
      //where("timestamp", ">=", new Date())
    );

    const unsubscribe1 = onSnapshot(q, (snapshot) => {
      const result = snapshot.docs.map((doc) => ({
        ...doc.data(),
        start: doc.data()?.timestamp?.toDate(),
        title: "Not Available",
        is_status: "notavailable",
      }));
      setLoader(false);
      //setData("task");
      //setData(result);
      settaskdata(result);
      // setEvents(result);
    });
  };

  const fetchSchaudel = async () => {
    const q = query(
      collection(db, "schaudel"),
      where("user_id", "==", props.id)
    );

    const unsubscribe2 = onSnapshot(q, (snapshot) => {
      const result = snapshot.docs.map((doc) => ({
        ...doc.data(),
        start: doc.data()?.date?.toDate(),
        title: "Available",
        is_status: "available",
      }));
      // const combinedArray = events.map((element, index) => result[index]);
      setSchaudeldata(result);
      //setEvents(combinedArray);
    });
  };

  useEffect(() => {
    fetchData();
    fetchSchaudel();
  }, [props.id]);

  useEffect(() => {
    const combinedArray = [...taskdata, ...schaudeldata];
    setEvents(combinedArray);
  }, [taskdata, schaudeldata]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Schedule Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loader === true && <MyThreeDots />}
        {loader === false && (
          <div>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              events={events}
              eventContent={renderEventContent}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                // right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              locale="en"
              // eventClick={(eventClickInfo) => {
              //   alert("Event clicked: " + eventClickInfo.event.title);
              // }}
              // // Callback for when the user clicks on a date cell or selects a range
              // select={(selectInfo) => {
              //   alert("Date selected: " + selectInfo.startStr);
              // }}
              themeSystem="bootstrap"
            />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Schedule;
