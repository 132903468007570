import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";
import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  updateDoc,
  where,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  app,
  db,
  auth,
  usercollection,
  eventcollection,
  taskcollection,
} from "../../../FirebaseConfig";
import { useForm } from "react-hook-form";

const EditDepartmentTask = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [event, setEvent] = useState([]);
  const [task, setTask] = useState([]);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      task: "",
      status: "pending",
      updated_date: Moment().format("L, h:mm:ss a"),
    },
  });

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };
  const onSubmit = async (inputdata) => {
    const docRef = doc(db, taskcollection, params.id);
    updateDoc(docRef, inputdata)
      .then((docRef) => {
        const notificationData = {
          to_id: task.to_id,
          type: "task",
          action: "update_task_status",
          taskName: inputdata.task,
          event_name: event.name,
          task_status: inputdata.status,
        };
        createNotification(notificationData).then(() => {
          successToast("Congratulations! Task updated successfully");
          navigate(-1);
        });
      })
      .catch((error) => {
        errorToast("Oops, something went wrong!");
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchEvents = async (event_id) => {
      try {
        const docRef = doc(db, eventcollection, event_id);
        const docSnap = await getDoc(docRef);
        setEvent(docSnap.data());
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async () => {
      const docRef = doc(db, taskcollection, params.id);
      const docSnap = await getDoc(docRef);
      const getdata = docSnap.data();
      fetchEvents(getdata.event_id);
      setTask(getdata);
      setValue("task", getdata.task);
      setValue("status", getdata.status);
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Edit Task Details</h5>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary add_user_btn_bar"
                >
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  &nbsp;Back
                </button>
              </div>

              <form
                method="post"
                onSubmit={handleSubmit(onSubmit, onErrors)}
                autocomplete="off"
              >
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="form-label">Task</label>
                      <textarea
                        name="task"
                        {...register("task", {
                          required: "Task is required",
                        })}
                        className="form-control"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="col-lg-12 mt-4">
                      <div className="">
                        <label for="largeSelect" className="form-label">
                          Status
                        </label>

                        <select
                          {...register("status", {
                            required: "Status is required",
                          })}
                          name="status"
                          className="form-select form-select-lg form_selected_for_custom"
                        >
                          <option value="">Select Status</option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      className="btn btn-primary me-2"
                      type="submit"
                      disabled={disabled}
                    >
                      {" "}
                      {disabled == false ? "Save" : "Saving..."}{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDepartmentTask;
