import React , {useEffect, useState} from 'react'
import { Link ,  useParams, useNavigate } from 'react-router-dom';
import { getDocs,getDoc , updateDoc ,  collection , onSnapshot , query , doc , where} from "firebase/firestore"; 
import { db , usercollection } from "../../../FirebaseConfig"
import { successToast , errorToast } from "../../../functions/Function";

const Edituser = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [disabled, setDisabled] = useState(false);
    const [edituser , setEdituser] = useState({})
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const docRef = doc(db, "users", params.id);
        const updateData = {
            name:edituser.name,
            phone:edituser.phone
          };
          console.log(updateData)

          updateDoc(docRef, updateData)
            .then(docRef => {
                successToast('Congratulations! User updated successfully')
                navigate("/admin/users");
            })
            .catch(error => {
                errorToast('Opps somthing wrong!');
                console.log(error);
            })

    }
    
    useEffect(() => {
        const getuserData = async() => 
        {
            try {
                const docRef = doc(db, "users", params.id);
                const docSnap = await getDoc(docRef);
                setEdituser(docSnap.data());
            } catch(error) {
                console.log(error)
            }
        }
        getuserData();
    }, []);
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <div className='user_name_add'>
                                    <h5 className="card-header card_hed_user">Edit User</h5>

                                    <Link to="/admin/users" className='user_list_add'>
                                        <button type="button" class="btn btn-primary add_user_btn_bar">
                                            <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                        </button>
                                    </Link>
                                </div>
                                <form onSubmit={handleSubmit} method='post'  >
                                <div class="card-body pt-0">
                                    <div>
                                        <label   class="form-label">Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={edituser.name}
                                            onChange={(e) => setEdituser(values => ({...values, name:e.target.value}))}
                                            placeholder="Enter Name"
                                             
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <label   class="form-label">Email</label>
                                        <input
                                            type="email"
                                            class="form-control"
                                            value={edituser.email}  
                                            placeholder="Email"
                                             
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <label   class="form-label">Phone No.</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={edituser.phone}
                                            onChange={(e) => setEdituser(values => ({...values, phone:e.target.value}))} 
                                            placeholder="Phone Number"
                                             
                                        />
                                    </div>
                                    
                                    <div class="mt-4 text-center">
                                      
                                        <button className="btn btn-primary me-2" type="submit" disabled={disabled}> {disabled == false ? 'Update' : 'Updating...'} </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Edituser;
