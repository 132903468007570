import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, usercollection } from "../../../FirebaseConfig";
import {
  MyThreeDots,
  timeshowAMPM,
  changeDateFotmate,
} from "../../../functions/Function";
const Eventview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [row, setRow] = useState({});
  const [servicesList, setServicesList] = useState([]);
  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "events", params.id);
        const docSnap = await getDoc(docRef);
        setRow(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();

    const fetchServices = async () => {
      const q = query(
        collection(db, "event_services"),
        where("event_id", "==", params.id)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServicesList(result);
      });
    };
    fetchServices();
  }, []);
  return (
    <>
      {console.log(row)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Event Details</h5>
                <button
                  onClick={() => navigate(-1)}
                  class="btn btn-primary add_user_btn_bar"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                </button>
              </div>
              <div class="card-body pt-0">
                {loader == true && MyThreeDots()}
                {loader == false && (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody className="table-border-bottom-0">
                        <tr>
                          <td colSpan={5} className="vieweventtitle">
                            <b>Event :</b> {row?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <b>Event Start</b>
                            </div>{" "}
                            {timeshowAMPM(row?.event_start_time)}
                          </td>
                          <td>
                            <div>
                              <b>Event End :</b>
                            </div>
                            {timeshowAMPM(row?.event_end_time)}
                          </td>
                          <td>
                            <div>
                              <b>Number Of People </b>
                            </div>
                            {row?.no_of_people}
                          </td>
                          <td>
                            <div>
                              <b>Res Start </b>
                            </div>{" "}
                            {timeshowAMPM(row?.res_start_time)}
                          </td>
                          <td>
                            <div>
                              <b>Res End </b>
                            </div>
                            {timeshowAMPM(row?.res_end_time)}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={5}>
                            <div>
                              {" "}
                              <b>Event Consultant </b>
                            </div>
                            <p>{row?.event_consultant}</p>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={5}>
                            <div>
                              {" "}
                              <b>Additional Comments </b>
                            </div>
                            <p>{row?.additional_comments}</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5}>
                            <div>
                              {" "}
                              <b>Location </b>
                            </div>
                            <p> {row?.location}</p>
                          </td>
                        </tr>
                        {servicesList.length > 0 && (
                          <tr>
                            <td colSpan={5}>
                              <div>
                                {" "}
                                <b>Services </b>
                              </div>

                              <table className="table table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th>S.NO.</th>
                                    <th>SERVICE TYPE</th>
                                    <th>SERVICE SERVING TIME</th>
                                    <th>SERVING ITEM</th>
                                    <th>SERVING QTY</th>
                                    <th>STATUS</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {servicesList.length > 0 &&
                                    servicesList.map((data, i) => (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          {" "}
                                          {data.type}
                                          {data.files.length > 0 && (
                                            <div>
                                              <p className="mb-2 colorAttachmentslabels">
                                                Uplaoded Attachments
                                              </p>
                                              <div className="servicesAttachmentss">
                                                {data.files.length > 0 &&
                                                  data.files.map((img, i) => (
                                                    <div className="mb-2 img-box-attachs">
                                                      <Link to={img}>
                                                        ATTACHMENT {i + 1}
                                                      </Link>
                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                        <td>{data?.serving_time}</td>
                                        <td> {data?.item}</td>
                                        <td> {data?.qty}</td>
                                        <td>
                                          {data?.status == "ASSIGNED" && (
                                            <span class="badge bg-label-success me-1 text-dark">
                                              ASSIGNED
                                            </span>
                                          )}

                                          {data?.status == "NOTASSIGNED" && (
                                            <span class="badge bg-label-inro me-1 text-dark">
                                              NOT ASSIGNED
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan={1}>
                            <b>Status</b>
                          </td>
                          <td colSpan={4}>
                            <p>
                              {row?.status == "completed" && (
                                <span class="badge bg-label-success me-1">
                                  COMPLETED
                                </span>
                              )}

                              {row?.status == "started" && (
                                <span class="badge bg-label-info me-1">
                                  STARTED
                                </span>
                              )}

                              {row?.status == "notstarted" && (
                                <span class="badge bg-label-danger me-1">
                                  NOTSTARTED
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eventview;
