//https://softauthor.com/firebase-firestore-update-document-data-updatedoc/
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// const firebaseConfig = {
//   apiKey: "AIzaSyB0zO9lylmVlHWBvubzYGGopWlZcekYCBM",
//   authDomain: "fir-7e708.firebaseapp.com",
//   databaseURL: "https://fir-7e708-default-rtdb.firebaseio.com",
//   projectId: "fir-7e708",
//   storageBucket: "fir-7e708.appspot.com",
//   messagingSenderId: "828453513172",
//   appId: "1:828453513172:web:d8f881218785005e589841",
//   measurementId: "G-RCP268GC9X",
// };

const firebaseConfig = {
  apiKey: "AIzaSyD4J9AzheqcrukIp1q4PRHTFPHbEvaovmg",
  authDomain: "events-68abc.firebaseapp.com",
  projectId: "events-68abc",
  storageBucket: "events-68abc.appspot.com",
  messagingSenderId: "1047967067855",
  appId: "1:1047967067855:web:492ee88a9f15e6c8d811ba",
  measurementId: "G-6FN9LDWGFM",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const usercollection = "users";
export const deparmentcollection = "department";
export const deparmentUsers = "department_users";
export const eventcollection = "events";
export const eventservicescollection = "event_services";
export const taskcollection = "tasks";
export const notificationscollection = "notifications";
export const checklistassigncollection = "checklist_assign";
export const auditoriumcollection = "auditorium_checklist";
export const porterchecklistcollection = "porter_checklist";
export const serverchecklistcollection = "server_checklist";
export const schaudelcollection = "schaudel";
