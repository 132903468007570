import React , {useState , useEffect} from 'react'
import { Link  , useNavigate} from 'react-router-dom';
import { successToast , errorToast , apibaseURl , getvalidationErr , formValidtionsMessages } from "../../../../functions/Function";
import { getFirestore, addDoc , getDoc , getDocs, collection , onSnapshot , query , doc , where} from "firebase/firestore"; 
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app ,  db ,  auth , auditoriumcollection} from '../../../../FirebaseConfig';
import { useForm } from "react-hook-form";
const AddAuditorium = () => {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const { register, handleSubmit, formState: { errors } , setValue , watch } = useForm({
     defaultValues: { 
        title: "",
        status:""
    }
    });
    
    const onErrors =  (errors) => {
        const errorLength = Object.keys(errors).length;
        if(errorLength > 0)
        {
            errorToast(formValidtionsMessages(errors) , 'top-right' , '8000'); 
        }
      };
    const onSubmit = async (data) => {
            setDisabled(true)
            addDoc(collection(db, auditoriumcollection), {
                title:data.title,
                status : data.status,
            });
            setDisabled(false)
            successToast('Record added successfully' , 'top-right' , '3000');
            navigate("/admin/auditorium")
    }
  return (
    <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className='user_name_add'>
                                    <h5 className="card-header card_hed_user">Add Auditorium</h5>
                                    <Link to="/admin/auditorium" className='user_list_add'>
                                        <button type="button" className="btn btn-primary add_user_btn_bar">
                                            <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                        </button>
                                    </Link>
                                </div>
                                <form method='post'  onSubmit={handleSubmit(onSubmit , onErrors)}  autocomplete="off">
                                    <div className="card-body pt-0">
                                        <div>
                                            <label     className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                placeholder="Enter Title"
                                                {...register('title', {
                                                    required: 'Title is required'
                                                  })}
                                            />
                                            
                                        </div>



                                        

                                        <div className='mt-4'>
                                                <label  className="form-label">Status</label>
                                                <select
                                                     {...register('status', {
                                                        required: 'Status is required',
                                                     })}
                                                     
                                                  
                                                 name="status"  
                                                className="form-select form-select-lg form_selected_for_custom">
                                                    <option value="">Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                        </div>


                                        <div className="mt-4 text-center">
                                           
                                            <button className="btn btn-primary me-2" type="submit" disabled={disabled}> {disabled == false ? 'Save' : 'Saving...'} </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default AddAuditorium;
