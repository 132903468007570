import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Moment from "moment";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getDocs,
  get,
  collection,
  updateDoc,
  onSnapshot,
  query,
  doc,
  where,
  orderBy,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
  deparmentUsers,
  eventcollection,
  taskcollection,
} from "../../../FirebaseConfig";
import {
  changeDateFotmate,
  loggedInUserDetails,
  successToast,
  errorToast,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";

const Task = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [joinedData, setJoinedData] = useState([]);
  const [data, setData] = useState([]);

  const handleClick = (id, row) => {
    navigate("/edittask/" + id, { state: row });
  };

  const updateTaskStatus = async (id, st, rowdata) => {
    const docRef = doc(db, "tasks", id);
    const updateData = {
      status: st,
    };
    updateDoc(docRef, updateData)
      .then((docRef) => {
        let actionsting = "";
        if (st == "completed") {
          actionsting = "update_task_status_completed_by_users";
        } else {
          actionsting = "update_task_status_completed_by_users";
        }

        const notificationData = {
          to_id: rowdata.department_id,
          type: "task",
          action: actionsting,
          event_name: rowdata.event.name,
          user_name: rowdata.to_user.name,
          task_status: st,
        };
        createNotification(notificationData).then(() => {
          successToast("Congratulations Your task status is changed.");
        });
      })
      .catch((error) => {
        errorToast("Opps somthing wrong!");
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch tasks collection
    const fetchTasks = async () => {
      const q = query(
        collection(db, taskcollection),
        where("to_id", "==", loggedInUserDetails("docid")),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setTasks(result);
        performLeftJoin();
      });
    };

    // Fetch event collection
    const fetchEvents = async () => {
      const q = query(collection(db, eventcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setEvents(result);
        performLeftJoin();
      });
    };

    // Fetch users collection
    const fetchUsers = async () => {
      const q = query(collection(db, usercollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
        performLeftJoin();
      });
    };

    // Fetch departments collection
    const fetchDepartments = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({ ...doc.data() }));
        setDepartments(result);
        performLeftJoin();
      });
    };
    fetchTasks();
    fetchUsers();
    fetchEvents();
    fetchDepartments();
  }, []);

  useEffect(() => {
    performLeftJoin();
  }, [users, departments, tasks]);

  // Perform left join operation
  const performLeftJoin = () => {
    const joinedDataWithUsersAndDepartments = tasks
      .map((join) => {
        const department = departments.find(
          (dept) => dept.id == join.department_id
        );
        const from_user = users.find((user) => user.docid == join.from_id);
        const to_user = users.find((user) => user.docid == join.to_id);
        const eventdata = events.find((event) => event.docid == join.event_id);
        //console.log(from_user)

        return {
          docid: join.docid,
          ...join,
          department: department,
          from_user: from_user,
          to_user: to_user,
          event: eventdata,
        };
      })
      .filter((element) => element.department !== undefined);
    setData(joinedDataWithUsersAndDepartments);
  };
  return (
    <>
      {/* {console.log(data)} */}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4 taskcard">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Tasks</h5>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  {data.length > 0 ? (
                    data.map((row, i) => (
                      <div
                        className="col-lg-12 col-md-12 col-12 mb-4"
                        key={row.docid}
                      >
                        <div
                          className={`card ${
                            row.status === "completed"
                              ? "taskcompleted"
                              : "taskpending"
                          }`}
                        >
                          <div className="card-body">
                            <div className="mb-0 card-title d-flex align-items-start justify-content-between">
                              <div>
                                <h5 className="event-name-task">
                                  <Link to={`event/view/${row?.event?.docid}`}>
                                    <i className="fa-solid fa-calendar-days menu-icon"></i>
                                    {row?.event?.name}{" "}
                                    <span
                                      className={`badge  me-1 ${
                                        row.status == "completed"
                                          ? "bgcustomlabel"
                                          : "bg-label-danger"
                                      }`}
                                    >
                                      {Moment(
                                        row?.event?.event_start_time,
                                        "HH:mm"
                                      ).format("hh:mm A")}{" "}
                                      To{" "}
                                      {Moment(
                                        row?.event?.event_end_time,
                                        "HH:mm"
                                      ).format("hh:mm A")}
                                    </span>
                                  </Link>
                                </h5>
                              </div>

                              <div className="dropdown">
                                <button
                                  className={`btn btn-sm ${
                                    row.status === "completed"
                                      ? "btn-success custom-btn-success"
                                      : "btn-danger "
                                  }`}
                                  type="button"
                                  id="cardOpt3"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {row.status == "completed"
                                    ? "Completed"
                                    : "Pending"}
                                  &nbsp;
                                  <i className="fa-solid fa-chevron-down"></i>
                                </button>
                                <div
                                  className="dropdown-menu dropdown-menu-end"
                                  aria-labelledby="cardOpt3"
                                >
                                  <Link
                                    className="dropdown-item"
                                    onClick={() =>
                                      updateTaskStatus(
                                        row.docid,
                                        "pending",
                                        row
                                      )
                                    }
                                  >
                                    Pending
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() =>
                                      updateTaskStatus(
                                        row.docid,
                                        "completed",
                                        row
                                      )
                                    }
                                  >
                                    Completed
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <span className="fw-semibold d-block mb-1">
                              <i className="fa-solid fa-table-cells-large menu-icon "></i>
                              {row?.department.department_name}
                            </span>
                            <p className="card-title mb-2">
                              <i className="fa-solid fa-list-check menu-icon"></i>
                              {row?.task}
                            </p>

                            <span
                              className={`badge  me-1 ${
                                row.status == "completed"
                                  ? " bgcustomlabel"
                                  : "bg-label-danger"
                              }`}
                            >
                              <small>
                                {Moment(row?.created_date, "DD MM YYYY").format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                <i>
                                  Start{" "}
                                  {Moment(row?.start_time, "HH:mm").format(
                                    "hh:mm A"
                                  )}
                                </i>{" "}
                                -{" "}
                                <i>
                                  End{" "}
                                  {Moment(row?.end_time, "HH:mm").format(
                                    "hh:mm A"
                                  )}
                                </i>
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12 col-md-12 col-12 mb-4 text-center">
                      <h4>You Have No Tasks</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
