import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { app, db, usercollection } from "../../../FirebaseConfig";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form";
import {
  successToast,
  errorToast,
  MyThreeDots,
  formValidtionsMessages,
} from "../../../functions/Function";
const Edit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [edituser, setEdituser] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      profile: "",
      status: "",
    },
  });
  const handleFileChange = async (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const storage = getStorage(app);
      const storageRef = ref(storage, `uploads/${file.name}`);
      await uploadBytes(storageRef, file)
        .then((snapshot) => {
          const downloadURL = getDownloadURL(snapshot.ref);
          downloadURL.then((data) => {
            setValue("profile", data);
          });
        })
        .catch((error) => {
          // Handle error uploading the profile photo
        });
    }
  };
  const removeImg = async () => {
    setValue("profile", "");
  };

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };

  const onSubmit = async (data) => {
    const docRef = doc(db, "users", params.id);
    const updateData = {
      name: data.name,
      phone: data.phone,
      status: data.status,
      profile: data.profile,
    };

    updateDoc(docRef, updateData)
      .then((docRef) => {
        successToast("Congratulations! User updated successfully");
        navigate(-1);
      })
      .catch((error) => {
        errorToast("Opps somthing wrong!");
        console.log(error);
      });
  };

  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "users", params.id);
        const docSnap = await getDoc(docRef);
        const row = docSnap.data();
        console.log(row);
        setValue("name", row.name);
        setValue("email", row.email);
        setValue("phone", row.phone);
        setValue("profile", row.profile);
        setValue("status", row.status);
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">
                  Edit Catering Manager Details
                </h5>

                <Link to="/admin/catering" className="user_list_add">
                  <button
                    type="button"
                    class="btn btn-primary add_user_btn_bar"
                  >
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    &nbsp;Back
                  </button>
                </Link>
              </div>

              {loader == true && MyThreeDots()}
              {loader == false && (
                <form
                  method="post"
                  onSubmit={handleSubmit(onSubmit, onErrors)}
                  autocomplete="off"
                >
                  <div className="card-body pt-0">
                    <div>
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "Name is required",
                          maxLength: {
                            value: 50,
                            message: "Name should not exceed 50 characters",
                          },
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Email"
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Phone No.</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone Number"
                        {...register("phone", {
                          required: "Phone number is required",
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label d-block">
                        Profile Photo
                      </label>

                      {watch("profile") && (
                        <div className="profileboxViewer">
                          <img
                            src={watch("profile")}
                            width={80}
                            className="mb-2"
                          />
                          <i
                            class="fa-regular fa-circle-xmark removeProfileImg"
                            onClick={() => removeImg()}
                          ></i>
                        </div>
                      )}

                      <input
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Status</label>
                      <select
                        {...register("status", {
                          required: "Status is required",
                        })}
                        name="status"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        className="btn btn-primary me-2"
                        type="submit"
                        disabled={disabled}
                      >
                        {" "}
                        {disabled == false ? "Save" : "Saving..."}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
