import Moment from "moment";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app, db, auth, usercollection } from "../FirebaseConfig";

export const changeDateFotmate = (date = "", format = "") => {
  return Moment(date).format(format);
};

export const timeshowAMPM = (time = "") => {
  const currentTime = Moment(time, "HH:mm");
  return currentTime.format("hh:mm a");
};

export const currencySymbol = "$";
export const filebasepath = "https://projects.softechplanet.com/oms/uploads/";
export const apibaseURl = "http://localhost:8000/";

export const uploadFiles = async (files = []) => {
  const storage = getStorage(app);
  const uploadPromises = [];

  for (const file of files) {
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytes(storageRef, file);
    uploadPromises.push(uploadTask);
  }

  try {
    const snapshots = await Promise.all(uploadPromises);

    const downloadURLPromises = snapshots.map((snapshot) => {
      const downloadURL = getDownloadURL(snapshot.ref);
      return downloadURL;
    });

    const downloadURLs = await Promise.all(downloadURLPromises);

    // Do something with the download URLs
    console.log(downloadURLs);

    // Return the download URLs if needed
    return downloadURLs;
  } catch (error) {
    // Handle error uploading the files
    console.error(error);
  }
};

export const getToken = () => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (getuserloggedIn) {
    if (getuserloggedIn.token != undefined && getuserloggedIn.token != "") {
      return getuserloggedIn.token;
    }
  }
  return false;
};

export const loggedInUserDetails = (key = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (getuserloggedIn) {
    if (
      getuserloggedIn.user[key] != undefined &&
      getuserloggedIn.user[key] != ""
    ) {
      return getuserloggedIn.user[key];
    }
  }
  return false;
};

export const getLoggedInuserRole = () => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (
    getuserloggedIn?.user["role"] != undefined &&
    getuserloggedIn.user["role"] != ""
  ) {
    return getuserloggedIn.user["role"];
  }

  return false;
};

export const getLoggedInuserDepatmentID = () => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (getuserloggedIn?.user?.department_id) {
    if (
      getuserloggedIn.user["department_id"] != undefined &&
      getuserloggedIn.user["department_id"] != ""
    ) {
      return getuserloggedIn.user["department_id"];
    }
  }
  return false;
};

export const loggedInCustomerDetails = (key = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));
  if (getuserloggedIn) {
    if (
      getuserloggedIn.user[key] != undefined &&
      getuserloggedIn.user[key] != ""
    ) {
      return getuserloggedIn.user[key];
    }
  }
  return false;
};

export const updateSession = (key = "", value = "") => {
  let getuserloggedIn = JSON.parse(localStorage.getItem("user"));

  if (getuserloggedIn) {
    getuserloggedIn["user"][key] = value;
    localStorage.setItem("user", JSON.stringify(getuserloggedIn));
  }
  return false;
};

export const errorToast = (
  text,
  positions = "top-right",
  autoClose = "3000"
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.error(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export const successToast = (
  text,
  positions = "top-right",
  autoClose = "3000",
  hideProgressBar = false
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.success(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: hideProgressBar,
  });
};

export const warningToast = (
  text,
  positions = "top-right",
  autoClose = "3000",
  hideProgressBar = false
) => {
  if (positions != "") {
    positions = positions;
  }
  toast.warning(text, {
    autoClose: autoClose,
    position: positions,
    transition: Zoom,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: hideProgressBar,
  });
};

export const getvalidationErr = (errors) => {
  const errorsConvertInArry = Object.values(errors);
  let collectAllValidationErr = [];
  errorsConvertInArry.forEach((error, index) => {
    collectAllValidationErr.push(<div key={index}>{error.msg}</div>);
  });
  const storeAllValidtionErr = <>{collectAllValidationErr}</>;

  return storeAllValidtionErr;
};

export const formValidtionsMessages = (errors) => {
  const errorsConvertInArry = Object.values(errors);
  let collectAllValidationErr = [];
  errorsConvertInArry.forEach((error, index) => {
    collectAllValidationErr.push(<div key={index}>{error.message}</div>);
  });
  const storeAllValidtionErr = <>{collectAllValidationErr}</>;

  return storeAllValidtionErr;
};

export const MyThreeDots = () => {
  return (
    <div className="MyThreeDots">
      <ThreeDots
        height={80}
        width={80}
        radius={9}
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export const Preloader = () => {
  return (
    <div className="MyThreeDots FullpageLoader">
      <ThreeDots
        height={120}
        width={120}
        radius={9}
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};
