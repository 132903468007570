import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import { db, usercollection } from "../../FirebaseConfig";
import {
  successToast,
  errorToast,
  MyThreeDots,
} from "../../functions/Function";

const Setting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "settings", "w1jg922uHE4UM7hlwHcb");
    const updateData = {
      admin_email: data.admin_email,
      admin_phone: data.admin_phone,
    };

    updateDoc(docRef, updateData)
      .then((docRef) => {
        successToast("Congratulations! User updated successfully");
      })
      .catch((error) => {
        errorToast("Opps somthing wrong!");
        console.log(error);
      });
  };
  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "settings", "w1jg922uHE4UM7hlwHcb");
        const docSnap = await getDoc(docRef);
        setData(docSnap.data());
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div class="row">
            <div class="col-md-12">
              <div class="card mb-4">
                <h5 class="card-header">Setting</h5>
                {console.log(data)}
                {loader == true && MyThreeDots()}
                {loader == false && (
                  <form onSubmit={handleSubmit} method="post">
                    <div class="card-body pt-0">
                      <div>
                        <label for="defaultFormControlInput" class="form-label">
                          Admin Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={data.admin_email}
                          onChange={(e) =>
                            setData((values) => ({
                              ...values,
                              admin_email: e.target.value,
                            }))
                          }
                          id="defaultFormControlInput"
                          placeholder="Enter Email"
                          aria-describedby="defaultFormControlHelp"
                        />
                      </div>

                      <div className="mt-4">
                        <label for="defaultFormControlInput" class="form-label">
                          Admin Phnoe No.
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          value={data.admin_phone}
                          onChange={(e) =>
                            setData((values) => ({
                              ...values,
                              admin_phone: e.target.value,
                            }))
                          }
                          id="defaultFormControlInput"
                          placeholder="Phone Number"
                          aria-describedby="defaultFormControlHelp"
                        />
                      </div>
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary me-2">
                          Save changes
                        </button>
                        <button type="reset" class="btn btn-outline-secondary">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
