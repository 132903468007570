import Moment from 'moment';
import { app , db,  auth , usercollection } from '../FirebaseConfig';
import { getFirestore, addDoc , getDocs, limit , DocumentData , where , QuerySnapshot , collection , onSnapshot , query , doc } from "firebase/firestore";



export const changeDateFotmate = (date='' , format='') => 
{
  return Moment(date).format(format);
}

export const getusers =  async () =>
{
  const querySnapshot = await getDocs(collection(db, 'users'));
  let result = [];
  querySnapshot.forEach((res) => {
    //console.log(res.data())
    result.push({...res.data()})
  });
 return result;
}

export const getuser =  async (uid='') =>
{
  let storeUserData;
  const q = query(collection(db, "users"), where("id", "==", uid));
  const querySnapshot =  await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    storeUserData =  { docid: doc.id, ...data};
   });
 return storeUserData;
}







   