import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  getFirestore,
  addDoc,
  serverTimestamp,
  collection,
  doc,
  where,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInCustomerDetails,
} from "../../../functions/Function";
import { app, db, auth, usercollection } from "../../../FirebaseConfig";

const Addchef = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      profile: "",
      password: "",
      confirm_password: "",
      status: "1",
    },
  });

  const password = watch("password");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storage = getStorage(app);
      const storageRef = ref(storage, `uploads/${file.name}`);
      await uploadBytes(storageRef, file)
        .then((snapshot) => {
          const downloadURL = getDownloadURL(snapshot.ref);
          downloadURL.then((data) => {
            setValue("profile", data);
          });
        })
        .catch((error) => {
          // Handle error uploading the profile photo
        });
    }
  };

  const removeImg = () => {
    setValue("profile", "");
  };

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };

  const onSubmit = async (data) => {
    setDisabled(true);
    await createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userData = {
          department_id: loggedInCustomerDetails("department_id"),
          id: user.uid,
          name: watch("name"),
          email: watch("email"),
          phone: watch("phone"),
          profile: watch("profile"),
          status: watch("status"),
          role: "chef",
          timestamp: serverTimestamp(),
          create_by: loggedInCustomerDetails("docid"),
        };
        addDoc(collection(db, usercollection), userData);
        successToast("Account created successfully", "top-right", "3000");
        setTimeout(() => {
          setDisabled(false);
          navigate(-1);
        }, 3000);
      })
      .catch((error) => {
        setDisabled(false);
        switch (error.code) {
          case "auth/email-already-in-use":
            errorToast("This email is already exist", "top-right", "8000");
            break;
          case "auth/missing-email":
            errorToast("Please enter an email", "top-right", "8000");
            break;
          case "auth/invalid-email":
            errorToast("Please enter a valid email", "top-right", "8000");
            break;
          case "auth/missing-password":
            errorToast("Please enter a valid password", "top-right", "8000");
            break;
          case "auth/weak-password":
            errorToast(error.message, "top-right", "8000");
            break;
          case "user-not-found":
            //returnAuth = messageUserNotFound;
            break;
        }
      });
  };

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="user_name_add">
                  <h5 className="card-header card_hed_user">Add Chef</h5>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-primary add_user_btn_bar"
                  >
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    &nbsp;Back
                  </button>
                </div>
                <form
                  method="post"
                  onSubmit={handleSubmit(onSubmit, onErrors)}
                  autoComplete="off"
                >
                  <div className="card-body pt-0">
                    <div>
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "Name is required",
                          maxLength: {
                            value: 50,
                            message: "Name should not exceed 50 characters",
                          },
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Email"
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Phone No.</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Phone Number"
                        {...register("phone", {
                          required: "Phone number is required",
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                          },
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        {...register("confirm_password", {
                          required: "Confirm Password is required",
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label d-block">
                        Profile Photo
                      </label>
                      {watch("profile") && (
                        <div className="profileboxViewer">
                          <img
                            src={watch("profile")}
                            width={80}
                            className="mb-2"
                            alt="Profile"
                          />
                          <i
                            className="fa-regular fa-circle-xmark removeProfileImg"
                            onClick={() => removeImg()}
                          ></i>
                        </div>
                      )}
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Status</label>
                      <select
                        {...register("status", {
                          required: "Status is required",
                        })}
                        name="status"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        className="btn btn-primary me-2"
                        type="submit"
                        disabled={disabled}
                      >
                        {disabled === false ? "Save" : "Saving..."}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addchef;
