import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import {
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import {
  db,
  deparmentcollection,
  usercollection,
  taskcollection,
} from "../../../FirebaseConfig";

const Attendance = () => {
  const startDate = new Date(); // You can set your desired start date here
  startDate.setDate(startDate.getDate() - 7);
  const endDate = new Date(); // You can set your desired end date here
  endDate.setDate(endDate.getDate()); // Set the number of days you want to display

  const [data, setData] = useState([]);
  const [usertype, setUsertype] = useState("server");
  const [user, setUser] = useState("");
  const [fromDate, setfromDate] = useState(startDate);

  const [toDate, settoDate] = useState(endDate);

  const [usersList, setusersList] = useState([]);
  const [Attendance, setAttendance] = useState([]);

  const refreshFilter = () => {
    setUser("");
    setfromDate(startDate);
    settoDate(endDate);
  };

  const selectUserType = (e) => {
    setUsertype(e.target.value);
  };

  const selectUser = (e) => {
    setUser(e.target.value);
  };
  const selectFromDate = (e) => {
    setfromDate(new Date(e.target.value));
  };
  const selectToDate = (e) => {
    settoDate(new Date(e.target.value));
    fetchTaskData();
  };

  const OptionsData = ({ optionvalue }) => {
    return (
      <>
        {optionvalue.length > 0 &&
          optionvalue.map((row, i) => (
            <option value={row.docid}>
              {row.name} , {row.role}
            </option>
          ))}
      </>
    );
  };
  const fetchTaskData = async () => {
    console.log(user);
    let q;
    if (user == "") {
      q = query(
        collection(db, taskcollection),
        // where("to_id", "==", user),
        // where("timestamp", "==", fromDate)
        where("timestamp", ">=", fromDate),
        where("timestamp", "<=", toDate)
      );
    } else {
      q = query(
        collection(db, taskcollection),
        where("to_id", "==", user),
        // where("timestamp", "==", fromDate)
        where("timestamp", ">=", fromDate),
        where("timestamp", "<=", toDate)
      );
    }

    const unsubscribe1 = onSnapshot(q, (snapshot) => {
      const result = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAttendance(result);
    });
    return () => {
      unsubscribe1();
    };
  };

  const TableHeader = () => {
    const tableHeaders = [];
    const currentDate = new Date(fromDate);
    while (currentDate <= toDate) {
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      tableHeaders.push(
        <th colSpan={3} key={currentDate.getTime()}>
          <div className="wth-200">{formattedDate}</div>
        </th>
      );

      currentDate.setDate(currentDate.getDate() + 1); // Increment date by 1 day
    }
    return tableHeaders;
  };

  const PersonName = (row) => {
    const matchingRecords = usersList.find((record) => {
      return row.row.to_id === record.docid;
    });
    if (matchingRecords != null && Object.keys(matchingRecords).length > 0) {
      return <td className="empnamecol">{matchingRecords?.name}</td>;
    } else {
      return <td className="empnamecol">Not Available</td>;
    }
  };

  const filterMatchingDate = (currentDate, row) => {
    const currentDateFormatted = currentDate.toLocaleDateString();

    const matchingRecord = Attendance.find((record) => {
      const timestamp = record.timestamp;
      const date = timestamp.toDate();
      const recordDate = date.toLocaleDateString();

      return recordDate === currentDateFormatted && row.row.id === record.id;
    });

    return matchingRecord || null; // Return the matching record or null if not found
  };

  const calculateWorkingHour = (time11 = "00:00", time22 = "00:00") => {
    const time1 = Moment(time11, "HH:mm");
    const time2 = Moment(time22, "HH:mm");

    const duration = Moment.duration(time2.diff(time1));

    const hours = duration.asHours();
    const Hoursview = hours.toFixed(0).toString().padStart(2, "0"); // Format hours as a two-digit string

    if (duration.asMinutes() < 60) {
      return `${duration.asMinutes()} Minutes`;
    } else {
      return `${Hoursview} Hours`;
    }
  };
  const AttendanceDetails = (row) => {
    const tableHeaders = [];
    const currentDate = new Date(fromDate);
    while (currentDate <= toDate) {
      const matchingRecords = filterMatchingDate(currentDate, row);

      if (matchingRecords != null && Object.keys(matchingRecords).length > 0) {
        tableHeaders.push(
          <>
            <td className="timimgcol starttimimgcol" width={`100px`}>
              {Moment(matchingRecords?.start_time, "HH:mm").format("hh:mm A")}
            </td>
            <td className="timimgcol endstarttimimgcol">
              {" "}
              {Moment(matchingRecords?.end_time, "HH:mm").format("hh:mm A")}
            </td>
            <td className="hoursCol totalHorse">
              {calculateWorkingHour(
                matchingRecords?.start_time,
                matchingRecords?.end_time
              )}
            </td>
          </>
        );
      } else {
        tableHeaders.push(
          <>
            <td className="absent"></td>
            <td className="absent"></td>
            <td className="absent"></td>
          </>
        );
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return tableHeaders;
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, usercollection)
        // where("role", "==", usertype)
        // where("role", "==", usertype)
      );
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setusersList(result);
      });
      return () => {
        unsubscribe1();
      };
    };
    fetchData();
  }, [usertype]);

  useEffect(() => {
    fetchTaskData();
  }, [usertype, user, toDate, fromDate]);

  return (
    <>
      {console.log(fromDate)}
      {/* {console.log(usersList)}  */}
      {/* {console.log(fromDate)}
      {console.log(toDate)}
      {console.log(Attendance)}
      {console.log(new Date(102451545 * 1000 + 7777775582538763 / 1000000))} */}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header mb-0 pb-2">Attendance List </h5>
              <p className="px-4">
                <small>
                  Attendance List by default shows data 30 days before the
                  current date. You can get more data by changing the date.
                </small>
              </p>

              <div className="card-body pt-0">
                <div className="row filterAttendanceRow">
                  {/* <div className="col-sm-3">
                    <label className="form-label">SELECT USER TYPE</label>
                    <select className="form-control" onChange={selectUserType}>
                      <option className="0">Select User Type</option>
                      <option value="supervisor">Supervisor</option>
                      <option value="server">Server</option>
                      <option value="porter">Porter</option>
                    </select>
                  </div> */}

                  <div className="col-sm-4">
                    <label className="form-label">SELECT USER</label>
                    <select
                      className="form-control"
                      onChange={selectUser}
                      value={user}
                    >
                      <option className="0">Select User</option>

                      <OptionsData optionvalue={usersList} />
                    </select>
                  </div>

                  <div className="col-sm-3">
                    <label className="form-label">FROM DATE</label>
                    <input
                      type="date"
                      value={Moment(fromDate).format("YYYY-MM-DD")}
                      className="form-control"
                      onChange={selectFromDate}
                    />
                  </div>
                  <div className="col-sm-3">
                    <label className="form-label">TO DATE</label>
                    <input
                      type="date"
                      className="form-control"
                      value={Moment(toDate).format("YYYY-MM-DD")}
                      onChange={selectToDate}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label className="form-label d-block">Filter Reset</label>
                    <button
                      className="btn btn-md btn-primary"
                      type="button"
                      onClick={() => refreshFilter()}
                    >
                      <i className="fa-solid fa-arrows-rotate"></i> Refresh
                    </button>
                  </div>
                </div>
                {Attendance.length > 0 ? (
                  <>
                    <div className="px-2 py-4">
                      <div className="float-rights">
                        <ul className="colorboxitems">
                          <li>
                            <span className="colorbox absent"></span>
                            <span className="colorboxlabel">No Working</span>
                          </li>

                          <li>
                            <span className="colorbox colorboxstarttime"></span>
                            <span className="colorboxlabel">Start Time</span>
                          </li>
                          <li>
                            <span className="colorbox colorboxendtime"></span>
                            <span className="colorboxlabel">End Hours</span>
                          </li>
                          <li>
                            <span className="colorbox colorboxtotalhourse"></span>
                            <span className="colorboxlabel">Total Hours</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-responsive custom  attendance_list ">
                      <table className="table table-md table-bordered">
                        <thead className="table-dark">
                          <tr>
                            <th>
                              {" "}
                              <div className="wth-200">Employee Name</div>
                            </th>

                            <TableHeader />

                            {/* <th colSpan={3}>1-Sept-Monday (2023)</th>
                        <th colSpan={3}>2-Sept-Tuesday (2023)</th>
                        <th colSpan={3}>3-Sept-Wednesday (2023)</th> */}
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {Attendance.map((row, i) => (
                            <tr>
                              <PersonName row={row} />
                              <AttendanceDetails row={row} />

                              {/* 
                          <td className="timimgcol">10.00 AM</td>
                          <td className="timimgcol"> 7 PM</td>
                          <td className="hoursCol">7 Hours</td>

                          <td className="timimgcol">10.00 AM</td>
                          <td className="timimgcol"> 7 PM</td>
                          <td className="hoursCol">7 Hours</td> */}
                            </tr>
                          ))}

                          {/* {data.map((row, i) => (
                    
                      ))} */}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <h5 className="text-center m-5">No Records Found</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
