import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import {
  app,
  db,
  usercollection,
  taskcollection,
  eventcollection,
  deparmentcollection,
} from "../../../FirebaseConfig";
import {
  successToast,
  errorToast,
  MyThreeDots,
  formValidtionsMessages,
} from "../../../functions/Function";
const EditTask = () => {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, "users", params.id);
        const docSnap = await getDoc(docRef);
        const row = docSnap.data();
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Edit Task</h5>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary add_user_btn_bar"
                >
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  &nbsp;Back
                </button>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="">
                      <label htmlFor="largeSelect" className="form-label">
                        Event List
                      </label>
                      <select
                        id="largeSelect"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option>Event Select</option>
                        <option value="1">Marriage party</option>
                        <option value="2">Birthday party</option>
                        <option value="3">Marriage anniversary</option>
                        <option value="3">Workplace Catering Manager</option>
                        <option value="3">New year Party</option>
                        <option value="3">Farewell Party</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="">
                      <label htmlFor="largeSelect" className="form-label">
                        Department Select
                      </label>
                      <select
                        id="largeSelect"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option>Department Select</option>
                        <option value="1">F&B Manager</option>
                        <option value="2">F&B Manager & Kitchen</option>
                        <option value="3">Exco Manager</option>
                        <option value="3">Workplace Catering Manager</option>
                        <option value="3">Liquor Manager</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6  mt-4">
                    <div className="">
                      <label htmlFor="largeSelect" className="form-label">
                        Users List
                      </label>
                      <select
                        id="largeSelect"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option>Select User</option>
                        <option value="1">Manish yadav | Porter </option>
                        <option value="2">Sunil Kumar | Server</option>
                        <option value="3">Ashok | Server </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 mt-4">
                    <div className="">
                      <label htmlFor="largeSelect" className="form-label">
                        Status
                      </label>
                      <select
                        id="largeSelect"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option>Select Status</option>
                        <option value="1">Completed</option>
                        <option value="2">Cancled</option>
                        <option value="3">Pending</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Department task
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-4 text-center">
                  <button type="submit" className="btn btn-primary me-2">
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTask;
