import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  getDocs,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where
} from 'firebase/firestore';
import { app, db, porterchecklistcollection } from '../../../../FirebaseConfig';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { useForm } from 'react-hook-form';
import {
  successToast,
  errorToast,
  MyThreeDots,
  formValidtionsMessages
} from '../../../../functions/Function';

const EditPorter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [edituser, setEdituser] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      title: '',
      status: ''
    }
  });

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), 'top-right', '8000');
    }
  };

  const onSubmit = async (data) => {
    const docRef = doc(db, porterchecklistcollection, params.id);
    const updateData = {
      title: data.title,
      status: data.status
    };

    updateDoc(docRef, updateData)
      .then((docRef) => {
        successToast('Congratulations! Record updated successfully');
        navigate(-1);
      })
      .catch((error) => {
        errorToast('Opps something went wrong!');
        console.log(error);
      });
  };

  useEffect(() => {
    const getuserData = async () => {
      try {
        const docRef = doc(db, porterchecklistcollection, params.id);
        const docSnap = await getDoc(docRef);
        const row = docSnap.data();
        console.log(row);
        setValue('title', row.title);
        setValue('status', row.status);
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="user_name_add">
                  <h5 className="card-header card_hed_user">Edit Porter Team Checklist</h5>
                  <button type="button"  onClick={()=> navigate(-1)} className="btn btn-primary add_user_btn_bar">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                    </button>
                </div>
                <form method="post" onSubmit={handleSubmit(onSubmit, onErrors)} autoComplete="off">
                  <div className="card-body pt-0">
                    <div>
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="Enter Title"
                        {...register('title', {
                          required: 'Title is required'
                        })}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="form-label">Status</label>
                      <select
                        {...register('status', {
                          required: 'Status is required'
                        })}
                        name="status"
                        className="form-select form-select-lg form_selected_for_custom"
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>

                    <div className="mt-4 text-center">
                      <button className="btn btn-primary me-2" type="submit" disabled={disabled}>
                        {disabled === false ? 'Save' : 'Saving...'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPorter;
