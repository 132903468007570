import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addEvent } from "../../../functions/Event";
import {
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  onSnapshot,
  deleteDoc,
  query,
  doc,
  where,
} from "firebase/firestore";
import { app, db, eventcollection } from "../../../FirebaseConfig";
import {
  successToast,
  errorToast,
  formValidtionsMessages,
  uploadFiles,
} from "../../../functions/Function";
import {
  AddEventTypeNotification,
  createNotification,
  departments,
} from "../../../functions/Notification";

import { useForm } from "react-hook-form";

const servicesList = [
  { id: 1, name: "Food Catering", department_id: 1002 },
  { id: 2, name: "Beverage Service", department_id: 1002 },
  { id: 3, name: "Water Service", department_id: 1001 },
  {
    id: 4,
    name: "Table Setup Shall include an attachment",
    department_id: 1001,
  },
  { id: 5, name: "Liquor Service", department_id: 1005 },
  { id: 6, name: "Working caterng", department_id: 1004 },
  { id: 7, name: "Exco", department_id: 1003 },
];
const Eventedit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [departmentlist, setDepartmentlist] = useState([]);
  const [services, setServicesList] = useState([]);
  const [serviceadd, setServiceadd] = useState({});
  const [Servicetype, setServicetype] = useState("");
  const [Servicetime, setServicetime] = useState("11:00");
  const [Selectitem, setSelectitem] = useState("");

  const [files, setFiles] = useState([]);
  const [showFileUpload, setshowFileUpload] = useState(false);
  const [qty, setQty] = useState(1);
  const [waste, setWaste] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      event_date: "",
      event_start_time: "",
      event_end_time: "",
      no_of_people: "",
      res_start_time: "",
      res_end_time: "",
      reservation_id: "",
      event_consultant: "",
      additional_comments: "",
      location: "",
      services: [],
    },
  });

  const handleChangeSerives = (arg = "") => {
    if (arg == "4") {
      setshowFileUpload(true);
    } else {
      setFiles([]);
      setshowFileUpload(false);
    }
    setServicetype(arg);
  };
  const handleAddTodo = () => {
    console.log(servicesList);
    if (!Servicetype || !Servicetime || !Selectitem) {
      if (Servicetype == "") {
        errorToast("Please select service type.");
        return;
      }

      if (Servicetime == "") {
        errorToast("Please select service time.");
        return;
      }

      if (Selectitem == "") {
        errorToast("Please add item.");
        return;
      }

      return;
    }
    const filteredRecords = servicesList.filter(
      (record) => record.id == Servicetype
    );
    const newTodo = {
      id: Date.now(),
      servicestype: filteredRecords[0].name,
      department_id: filteredRecords[0].department_id,
      servingtime: Servicetime,
      item: Selectitem,
      qty: qty,
      waste: waste,
      files: files,
    };

    const dbservicesRef = collection(db, "event_services");
    addDoc(dbservicesRef, {
      event_id: params.id,
      department_id: filteredRecords[0].department_id,
      item: Selectitem,
      qty: qty,
      serving_time: Servicetime,
      type: filteredRecords[0].name,
      serving_time: Servicetime,
      waste: waste,
      files: files,
      status: "NOTASSIGNED",
    });

    //setServiceadd([...serviceadd, newTodo]);
    setFiles([]);
    setshowFileUpload(false);
    setServicetype("");
    setServicetime("11:00");
    setSelectitem("");
    setQty(1);
  };

  const handleDeleteTodo = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this service!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "event_services", id));
          Swal.fire("Deleted!", "The record has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    });
  };

  // service type end==================================>

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };

  const onSubmit = async (data) => {
    const docRef = doc(db, "events", params.id);
    updateDoc(docRef, {
      name: data.name,
      event_date: data.event_date,
      event_start_time: data.event_start_time,
      event_end_time: data.event_end_time,
      no_of_people: data.no_of_people,
      res_start_time: data.res_start_time,
      res_end_time: data.res_end_time,
      reservation_id: data.reservation_id,
      event_consultant: data.event_consultant,
      additional_comments: data.additional_comments,
      location: data.location,
    })
      .then((docRef) => {
        const promises = [];
        if (departmentlist.length > 0) {
          departmentlist.forEach((department) => {
            const promise = createNotification({
              to_id: department.department_id.toString(),
              type: "event",
              action: "update_event",
              event_name: data.name,
            });

            promises.push(promise);
          });
        }

        Promise.all(promises).then(() => {
          successToast("Congratulations! Event updated successfully");
          navigate(-1);
        });
      })
      .catch((error) => {
        errorToast("Oops, something went wrong!");
        console.log(error);
      });
  };

  const handleFileChange = async (e) => {
    const filesURl = await uploadFiles(e.target.files);
    setFiles(filesURl);
  };

  const fetechDepartment = async () => {
    const departmentslist = await departments();
    setDepartmentlist(departmentslist);
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "event_services"),
        where("event_id", "==", params.id)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setServicesList(result);
      });

      return () => {
        // Unsubscribe from the snapshot listener when the component unmounts
        unsubscribe();
      };
    };

    fetchData();

    fetechDepartment();
    const getuserData = async () => {
      try {
        const docRef = doc(db, "events", params.id);
        const docSnap = await getDoc(docRef);
        const getdata = docSnap.data();
        setValue("name", getdata.name);
        setValue("event_date", getdata.event_date);
        setValue("event_start_time", getdata.event_start_time);
        setValue("event_end_time", getdata.event_end_time);
        setValue("no_of_people", getdata.no_of_people);
        setValue("res_start_time", getdata.res_start_time);
        setValue("res_end_time", getdata.res_end_time);
        setValue("reservation_id", getdata.reservation_id);
        setValue("event_consultant", getdata.event_consultant);
        setValue("additional_comments", getdata.additional_comments);
        setValue("location", getdata.location);
        setServiceadd(getdata.services);
        // setValue('services' , getdata.services);
      } catch (error) {
        console.log(error);
      }
    };
    getuserData();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Edit Event</h5>
                <button
                  onClick={() => navigate(-1)}
                  class="btn btn-primary add_user_btn_bar"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                </button>
              </div>
              <form
                method="post"
                onSubmit={handleSubmit(onSubmit, onErrors)}
                autoComplete="off"
              >
                <div class="card-body pt-0">
                  <div>
                    <label class="form-label">Event Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Birthday Party"
                      name="name"
                      {...register("name", {
                        required: "Event name is required",
                      })}
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Event Date</label>
                    <input
                      type="date"
                      class="form-control"
                      name="event_date"
                      {...register("event_date", {
                        required: "Event date is required",
                      })}
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Event Start Time</label>
                    <input
                      type="time"
                      class="form-control"
                      name="event_start_time"
                      {...register("event_start_time", {
                        required: "Event start time is required",
                      })}
                      placeholder="Event Start"
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Event End Time</label>
                    <input
                      type="time"
                      class="form-control"
                      name="event_end_time"
                      {...register("event_end_time", {
                        required: "Event end time is required",
                      })}
                      placeholder="Event End"
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">NUMBER OF PEOPLE</label>
                    <input
                      type="number"
                      class="form-control"
                      name="no_of_people"
                      {...register("no_of_people", {
                        required: "No  of people is required",
                      })}
                      placeholder="50"
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Res Start Time</label>
                    <input
                      type="time"
                      class="form-control"
                      name="res_start_time"
                      {...register("res_start_time", {
                        required: "Res start time is required",
                      })}
                      placeholder="Res Start Date"
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Res End Time</label>
                    <input
                      type="time"
                      class="form-control"
                      name="res_end_time"
                      {...register("res_end_time", {
                        required: "Res end time is required",
                      })}
                      placeholder="Res End Date"
                    />
                  </div>

                  <div className="mt-4">
                    <label class="form-label">Reservation ID</label>
                    <input
                      type="text"
                      class="form-control"
                      name="reservation_id"
                      {...register("reservation_id", {
                        required: "Reservation id is required",
                      })}
                      placeholder="Reservation ID"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="form-label">Event Consultant </label>
                    <textarea
                      className="form-control"
                      name="event_consultant"
                      {...register("event_consultant", {
                        required: "Event consultantis required",
                      })}
                      placeholder="Event Consultant"
                      rows="3"
                    ></textarea>
                  </div>

                  <div className="mt-4">
                    <label className="form-label">Additional Comments</label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      {...register("additional_comments", {
                        required: "Additional comments required",
                      })}
                      placeholder="Additional Comments"
                      rows="3"
                    ></textarea>
                  </div>

                  <div className="mt-4">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Location"
                      name="location"
                      {...register("location", {
                        required: "Location required",
                      })}
                    />
                  </div>

                  <div className="mt-4">
                    <h5 className="p-0 m-0">Service</h5>
                    <hr className="p-0 mt-2  mb-2" />
                  </div>

                  <div className="col-lg-12 mt-4">
                    <table class="table table-sm table-bordered mb-4 addservicestable">
                      <thead>
                        <tr>
                          <th width={`28%`}>
                            <label className="d-block">SERVICES</label>
                            <select
                              className="selectservicewidth w-100 d-block"
                              onChange={(e) =>
                                handleChangeSerives(e.target.value)
                              }
                              value={Servicetype}
                            >
                              <option value={""}>SELECT SERVICE</option>
                              <option value="1">Food Catering</option>
                              <option value="2">Beverage Service</option>
                              <option value="3">Water Service</option>
                              <option value="4">
                                Table Setup Shall include an attachment
                              </option>
                              <option value="5">Liquor Service</option>
                              <option value="6">Working caterng</option>
                              <option value="7">Exco</option>
                            </select>
                            {showFileUpload == true && (
                              <>
                                <div className="mt-2">
                                  <label className="d-block">
                                    Select Attachments (Only PDF)
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept="application/pdf"
                                  />
                                </div>

                                {files.length > 0 && (
                                  <>
                                    <div className="servicesAttachmentsa">
                                      {files.map((img, i) => (
                                        <div className="mt-2 img-box-attacha">
                                          <Link to={img}>
                                            Attachment {i + 1}
                                          </Link>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </th>
                          <th width={`20%`}>
                            <label className="d-block">Serving Time</label>
                            <input
                              className="w-100"
                              type="text"
                              placeholder="11 AM"
                              value={Servicetime}
                              onChange={(e) => setServicetime(e.target.value)}
                            />
                          </th>
                          <th width={`30%`}>
                            <label className="d-block">Item</label>
                            <input
                              type="text"
                              className="w-100"
                              placeholder="E.g. Pizza"
                              value={Selectitem}
                              onChange={(e) => setSelectitem(e.target.value)}
                            />
                          </th>

                          <th width={`10%`}>
                            <label className="d-block">Qty</label>
                            <input
                              type="number"
                              className="w-100"
                              placeholder="E.g. 10"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            />
                          </th>

                          <th>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={handleAddTodo}
                            >
                              Add New
                            </button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    {services.length > 0 && (
                      <table class="table table-sm table-bordered addservicestable">
                        <thead>
                          <tr>
                            <th width={`28%`}>SERVICE TYPE</th>
                            <th width={`20%`}>SERVINGTIME</th>
                            <th width={`30%`}>ITEM</th>
                            <th width={`10%`}>Qty</th>

                            <th>REMOVE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {services.map((todo, i) => (
                            <tr>
                              <td>
                                <>
                                  {todo.type}
                                  {todo.files.length > 0 && (
                                    <div>
                                      <p className="mb-2 colorAttachmentslabels">
                                        Uplaoded Attachments
                                      </p>
                                      <div className="servicesAttachmentss">
                                        {todo.files.length > 0 &&
                                          todo.files.map((img, i) => (
                                            <div className="mb-2 img-box-attachs">
                                              <Link to={img}>
                                                ATTACHMENT {i + 1}
                                              </Link>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  )}
                                </>
                              </td>
                              <td>{todo.serving_time}</td>
                              <td>{todo.item}</td>
                              <th>{todo.qty}</th>

                              <th>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDeleteTodo(todo.id)}
                                >
                                  Delete
                                </button>
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div class="mt-4 text-center">
                    <button
                      className="btn btn-primary me-2"
                      type="submit"
                      disabled={disabled}
                    >
                      {disabled == false ? "Update Event" : "Update..."}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eventedit;
