import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import header_img from "../../../assest/admin_assest/img/avatars/1.png";
import { AppContext } from "../../../App";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  addDoc,
  getDocs,
  limit,
  DocumentData,
  where,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
  doc,
} from "firebase/firestore";
import { app, db, auth, usercollection } from "../../../FirebaseConfig";
import {
  loggedInUserDetails,
  getLoggedInuserRole,
} from "../../../functions/Function";
import { departmentNotifications } from "../../../functions/Notification";

const Header = (props) => {
  const AppContextData = useContext(AppContext);

  return (
    <>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <span className="nav-item nav-link px-0 me-xl-4" to="/">
            <i
              className="fa fa-bars"
              aria-hidden="true"
              onClick={props.handleClick}
            ></i>
          </span>
        </div>

        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="h-full d-flex align-items-center">
            {getLoggedInuserRole("role") == "manager" && (
              <h2 className="text-white m-0">
                {props?.department?.department_name}
              </h2>
            )}
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <Link
                className="github-button nav-link dropdown-toggle hide-arrow"
                to=""
                data-bs-toggle="dropdown"
              >
                <i className="fa fa-bell-o bell_icon" aria-hidden="true">
                  {props.notifications.length > 0 && (
                    <sup className="sup_rep">{props.notifications.length}</sup>
                  )}
                </i>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end menu_item_bar">
                <div className="main_noti">
                  <div className="notification_Header bg-dark text-white">
                    <span>Notification</span>
                    {/* <span>Clear All</span> */}
                  </div>
                </div>
                {props.notifications.length > 0 &&
                  props.notifications.map((row, i) => (
                    <>
                      <li className="bgsuccess-" key={row.docid}>
                        <Link className="dropdown-item" to="#">
                          <div className="flex_top_wrep">
                            <div>
                              <div>{row.message}</div>
                              <span className="badge bg-label-dark me-1">
                                <i className="fa-solid fa-clock"></i>{" "}
                                {row.createdAt}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                      {/* {row?.type == "event" && (
                        <li className="bgsuccess-">
                          <Link className="dropdown-item" to="#">
                            <div className="flex_top_wrep">
                              <div>
                                <div>
                                  <b>{row.event.event_name}</b>
                                </div>
                                <div>{row.notification}</div>
                                <span className="badge bg-label-dark me-1">
                                  <i className="fa-solid fa-clock"></i>{" "}
                                  {row.created_date}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )}


                      {row?.type == "user" && (
                        <li className="bgsuccess-">
                          <Link className="dropdown-item" to="#">
                            <div className="flex_top_wrep">
                              <div>
                                <div>
                                  <b>{row?.event?.event_name}</b>
                                </div>
                                <div>{row.notification}</div>
                                <span className="badge bg-label-dark me-1">
                                  <i className="fa-solid fa-clock"></i>{" "}
                                  {row.created_date}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )} */}
                    </>
                  ))}
                {props.notifications.length > 0 ? (
                  <div className="main_noti1">
                    <div className="text-center">
                      <Link
                        to="notification"
                        className="btn btn-sm btn-block btn-primary"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="main_noti1">
                    <div className="text-center">
                      <p>Notification Not Available</p>
                      <Link
                        to="notification"
                        className="btn btn-sm btn-block btn-primary"
                      >
                        Notifications
                      </Link>
                    </div>
                  </div>
                )}
              </ul>
            </li>

            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <Link
                className="nav-link dropdown-toggle hide-arrow"
                to="#"
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  {loggedInUserDetails("profile") != "" ? (
                    <img
                      src={loggedInUserDetails("profile")}
                      className="w-px-40 h-auto rounded-circle"
                    />
                  ) : (
                    <img
                      src={header_img}
                      className="w-px-40 h-auto rounded-circle"
                    />
                  )}
                </div>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="#">
                    <div className="d-flexe">
                      <div className="flex-shrink-0">
                        <div className="avatar avatar-online">
                          {loggedInUserDetails("profile") != "" ? (
                            <img
                              src={loggedInUserDetails("profile")}
                              className="w-px-40 h-auto rounded-circle"
                            />
                          ) : (
                            <img
                              src={header_img}
                              className="w-px-40 h-auto rounded-circle"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-semibold d-block pt-2">
                          {loggedInUserDetails("name")}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="profile">
                    <i className="fa fa-user" aria-hidden="true">
                      &nbsp;&nbsp;
                    </i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                {getLoggedInuserRole() == "administrator" && (
                  <li>
                    <Link className="dropdown-item" to="setting">
                      <i className="fa fa-cog" aria-hidden="true">
                        &nbsp;&nbsp;
                      </i>
                      <span className="align-middle">Settings</span>
                    </Link>
                  </li>
                )}
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => AppContextData.Logout()}
                  >
                    <i className="fa fa-sign-out" aria-hidden="true">
                      &nbsp;
                    </i>
                    <span className="align-middle"> Log Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
