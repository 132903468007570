import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getFirestore,
  addDoc,
  getDocs,
  limit,
  DocumentData,
  where,
  QuerySnapshot,
  collection,
  onSnapshot,
  updateDoc,
  getDoc,
  query,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { db, usercollection } from "../../../FirebaseConfig";
import { MyThreeDots, loggedInUserDetails } from "../../../functions/Function";

const Notification = (props) => {
  const [notifications, setnotifications] = useState([]);
  const updateNotificationStatus = async (row) => {
    try {
      const updateData = {
        isRead: true,
      };
      const docRef = doc(db, "notifications", row.docid);
      await updateDoc(docRef, updateData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let setnotificationsTokenIDs;
    if (loggedInUserDetails("role") == "manager") {
      setnotificationsTokenIDs = loggedInUserDetails("department_id");
    }
    if (loggedInUserDetails("role") != "manager") {
      setnotificationsTokenIDs = loggedInUserDetails("docid");
    }

    //alert(setnotificationsTokenIDs);
    //console.log(setnotificationsTokenIDs);
    const fetchData = async () => {
      const q = query(
        collection(db, "notifications"),
        where("to_id", "==", setnotificationsTokenIDs),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result1 = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        // console.log("result");
        console.log(result1);
        setnotifications(result1);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card bg-dark">
              <div className="user_name_add">
                <h5 className="card-header text-warning ">
                  <i
                    className="text-warning  fa fa-bell-o bell_icon d-inline-block mr-2"
                    aria-hidden="true"
                  ></i>{" "}
                  Notification
                </h5>
              </div>
            </div>

            {notifications.length > 0 &&
              notifications.map((row, i) => (
                <>
                  <div
                    className={`card mt-2 notificationlistitem ${
                      row.isRead === true
                        ? "notificationlistitemread"
                        : "notificationlistitemunread"
                    }`}
                  >
                    <Link onClick={() => updateNotificationStatus(row)}>
                      <div className="card-body p-0">
                        <div className="d-flex justify-content-between px-4 py-3">
                          <div>{row.message}</div>
                          <span class="badge bg-label-dark me-1">
                            <i class="fa-solid fa-clock"></i> {row.createdAt}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
