import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  getDocs,
  collection,   
  deleteDoc,
  onSnapshot,
  query,
  doc,
  where
} from 'firebase/firestore';
import { db, serverchecklistcollection } from '../../../../FirebaseConfig';
import {
  timeshowAMPM,
  changeDateFotmate
} from '../../../../functions/Function';

const ServerTeam = () => {
  const [data, setData] = useState([]);
  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, serverchecklistcollection, id));
          Swal.fire('Deleted!', 'The record has been deleted.', 'success');
        } catch (error) {
          Swal.fire(
            'Error!',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, serverchecklistcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setData(result);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Server Team Checklist </h5>
                <Link to="add" className="btn btn-primary add_user_btn_bar">
                  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add
                  Checklist
                </Link>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>
                        <th className="td_head_name">Title</th>
                        <th>Status</th>
                        <th className='w-120'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {data.length > 0 &&
                        data.map((row, i) => (
                          <tr key={row.id}>
                            <td>{i + 1}</td>
                            <td>
                              <strong>{row.title}</strong>
                            </td>
                            <td>
                              {row?.status == '1' && (
                                <span className="badge bg-label-success me-1">
                                  Active
                                </span>
                              )}
                              {row?.status == '0' && (
                                <span className="badge bg-label-danger me-1">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              <div>
                                <Link
                                  to={`edit/${row.id}`}
                                  className="btn btn-icon btn-primary ball_icon"
                                >
                                  <span className="tf-icons fa fa-pencil-square"></span>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-icon btn-danger"
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <span className="tf-icons fa fa-trash"></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerTeam;
