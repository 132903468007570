import React, {
  Suspense,
  lazy,
  useState,
  createContext,
  useEffect,
  useRef,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Redirect,
  Route,
  Link,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getAuth, signOut, deleteUser } from "firebase/auth";
import {
  getToken,
  apibaseURl,
  getLoggedInuserRole,
  loggedInUserDetails,
} from "./functions/Function";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  addDoc,
  getDocs,
  limit,
  DocumentData,
  where,
  QuerySnapshot,
  collection,
  onSnapshot,
  getDoc,
  query,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import {
  app,
  db,
  auth,
  usercollection,
  deparmentcollection,
} from "./FirebaseConfig";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/js/dist/collapse.js";
import "./assest/admin_assest/css/demo.css";
import "./assest/admin_assest/css/core.css";
import "./assest/admin_assest/css/theme-default.css";
import "./assest/admin_assest/css/page-auth.css";

import Layout from "./admin/component/layout/Layout";

import Dashboard from "./admin/pages/dashboard/Dashboard";

import User from "./admin/pages/user/User";
import Adduser from "./admin/pages/user/Adduser";
import Viewuser from "./admin/pages/user/Viewuser";
import Edituser from "./admin/pages/user/Edituser";

import Catering from "./admin/pages/catering/Catering";
import AddCatering from "./admin/pages/catering/Add";
import EditCatering from "./admin/pages/catering/Edit";

import Setting from "./admin/pages/Setting";
import Profile from "./admin/pages/myaccount/Profile";

import Department from "./admin/pages/department/Department";

import Editdepartment from "./admin/pages/department/Editdepartment";
import Viewdepartment from "./admin/pages/department/Viewdepartment";

import Event from "./admin/pages/events/Event";
import Eventadd from "./admin/pages/events/Eventadd";
import Eventedit from "./admin/pages/events/Eventedit";
import Eventview from "./admin/pages/events/Eventview";
import Eventviewglobal from "./admin/pages/events/Eventviewglobal";
import Task from "./admin/pages/taskmanager/Task";
import Addtask from "./admin/pages/taskmanager/Addtask";
import Supervisor from "./admin/pages/supervisor/Supervisor";
import Addsupervisor from "./admin/pages/supervisor/Addsupervisor";
import Editsupervisor from "./admin/pages/supervisor/Editsupervisor";
import Viewsupervisor from "./admin/pages/supervisor/Viewsupervisor";
import Server from "./admin/pages/server/Server";
import Addserver from "./admin/pages/server/Addserver";
import Editserver from "./admin/pages/server/Editserver";
import Viewserver from "./admin/pages/server/Viewserver";

import Chef from "./admin/pages/chef/Chef";
import Addchef from "./admin/pages/chef/Addchef";
import Editchef from "./admin/pages/chef/Editchef";
import Viewchef from "./admin/pages/chef/Viewchef";

import Cook from "./admin/pages/cook/Cook";
import Addcook from "./admin/pages/cook/Addcook";
import Editcook from "./admin/pages/cook/Editcook";
import Viewcook from "./admin/pages/cook/Viewcook";

import DepartmentTask from "./admin/pages/departmenttask/DepartmentTask";
import AddDepartmentTask from "./admin/pages/departmenttask/AddDepartmentTask";
import EditDepartmenttask from "./admin/pages/departmenttask/EditDepartmenttask";
import Supervisortask from "./admin/pages/supervisortask/Supervisortask";
import SupervisorTaskResponce from "./admin/pages/supervisortask/SupervisorTaskResponce";

import ServerTaskResponce from "./admin/pages/servertask/ServerTaskResponce";
import ServermanagerTask from "./admin/pages/servertask/ServermanagerTask";
import Login from "./admin/pages/Login";
import Signup from "./admin/pages/Signup";
import EditTask from "./admin/pages/taskmanager/EditTask";

import Auditorium from "./admin/pages/masters/Aaudiotorim/Auditorium";
import EditAuditorium from "./admin/pages/masters/Aaudiotorim/EditAuditorium";
import AddAuditorium from "./admin/pages/masters/Aaudiotorim/AddAuditorium";
import ViewAuditorium from "./admin/pages/masters/Aaudiotorim/ViewAuditorium";

import PorterTeam from "./admin/pages/masters/porter/Porter";
import EditPorterTeam from "./admin/pages/masters/porter/EditPorter";
import AddPorterTeam from "./admin/pages/masters/porter/AddPorter";
import ViewPorterTeam from "./admin/pages/masters/porter/ViewPorter";

import ServerTeam from "./admin/pages/masters/serverteam/ServerTeam";
import AddServerTeam from "./admin/pages/masters/serverteam/AddServerTeam";
import ViewServerTeam from "./admin/pages/masters/serverteam/ViewServerTeam";
import EditServerTeam from "./admin/pages/masters/serverteam/EditServerTeam";

import Service from "./admin/pages/myservice/Service";
import DepartmentManager from "./admin/pages/departmentManager/DepartmentManager";
import SupervisorMain from "./admin/pages/user/supervisor/SupervisorMain";
import ServerMain from "./admin/pages/user/server/ServerMain";
import PorterMain from "./admin/pages/user/porter/PorterMain";
import PorterMainManager from "./admin/pages/porter/PorterMainManager";
import AddPorterMain from "./admin/pages/porter/AddPorterMain";
import EditPorterMain from "./admin/pages/porter/EditPorterMain";
import ViewPorterMainManager from "./admin/pages/porter/ViewPorterMainManager";
import DepartmentManagerView from "./admin/pages/departmentManager/DepartmentManagerView";
import SuperVisorMainView from "./admin/pages/user/supervisor/SuperVisorMainView";
import ServerMainView from "./admin/pages/user/server/ServerMainView";
import PorterMainView from "./admin/pages/user/porter/PorterMainView";
import { departmentNotifications } from "./functions/Notification";

import Taskportal from "./admin/pages/task/Task";
import Checklist from "./admin/pages/checklist/Checklist";
import Checklistview from "./admin/pages/checklist/Checklistview";
import Departmentchecklist from "./admin/pages/checklist/Departmentchecklist";
import Notifications from "./admin/pages/notification/Notifications";
import Attendance from "./admin/pages/attendance/Attendance";
import Scheduleadd from "./admin/pages/schedule/Scheduleadd";

const AppContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [token, setToken] = useState("");
  const [isUserLoggedin, setIsUserLoggedin] = useState(true);
  const [reloadApp, setreloadApp] = useState(false);
  const [totalNotificationCount, settotalNotificationCount] = useState(0);
  const [notifications, setnotifications] = useState([]);
  const [notificationsTokenID, setnotificationsTokenID] = useState("0");
  const [department, setDepartment] = useState({});

  const updateToken = (token = "") => {
    setToken(token);
  };
  const Logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        updateToken("");
        setIsUserLoggedin(false);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const Loginprotected = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "administrator") {
      return <Navigate to="/admin/dashboard" replace />;
    }

    if (getToken() != false && getLoggedInuserRole() == "manager") {
      return <Navigate to="/department/dashboard" replace />;
    }

    if (getToken() != false && getLoggedInuserRole() == "catering") {
      return <Navigate to="/catering/dashboard" replace />;
    }

    if (getToken() != false && getLoggedInuserRole() == "supervisor") {
      return <Navigate to="/supervisor/dashboard" replace />;
    }

    if (getLoggedInuserRole() == "server") {
      return <Navigate to="/server/dashboard" replace />;
    }

    if (getLoggedInuserRole() == "porter") {
      return <Navigate to="/porter/dashboard" replace />;
    }

    return children;
  };

  const Privateprotected = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "administrator") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const PrivateprotectedDepartment = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "manager") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const Privateprotectedcatering = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "catering") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const Privateprotectedsupervisor = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "supervisor") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const Privateprotectedserver = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "server") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const Privateprotectedporter = ({ children }) => {
    if (getToken() != false && getLoggedInuserRole() == "porter") {
      return children;
    }
    return <Navigate to="/admin/login" replace />;
  };

  const getLoggedInUserDetails = () => {
    fetch(apibaseURl + "getuser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
      });
  };

  const reloadAppToggle = () => {
    setreloadApp(!reloadApp);
  };

  const updateNotifications = () => {
    const department_Notifications = departmentNotifications();
    department_Notifications.then((data) => {
      settotalNotificationCount(data.notifications.length);
    });
  };

  const AppContextdata = {
    Logout: Logout,
    reloadApp: reloadAppToggle,
    updateNotifications: updateNotifications,
    totalNotificationCount: totalNotificationCount,
    setnotifications: setnotifications,
    notifications: notifications,
    notificationsTokenID: notificationsTokenID,
  };

  const getDepartmentDetails = async () => {
    try {
      // console.log(loggedInUserDetails("department_id"));
      const userDepartmentId = Number(loggedInUserDetails("department_id"));
      const q = query(
        collection(db, deparmentcollection), // Replace with your actual collection name
        where("id", "==", userDepartmentId)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const departmentData = doc.data();
          // console.log(departmentData);
          setDepartment(departmentData);
        });
      } else {
        console.log("No departments found for the provided department ID");
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  useEffect(() => {
    updateNotifications();
    setToken(getToken());
    getDepartmentDetails();
  }, []);

  useEffect(() => {
    getDepartmentDetails();
  }, [isUserLoggedin]);

  useEffect(() => {
    let setnotificationsTokenIDs;
    if (loggedInUserDetails("role") == "manager") {
      setnotificationsTokenIDs = loggedInUserDetails("department_id");
    }
    if (loggedInUserDetails("role") != "manager") {
      setnotificationsTokenIDs = loggedInUserDetails("docid");
    }

    //alert(setnotificationsTokenIDs);
    //console.log(setnotificationsTokenIDs);
    const fetchData = async () => {
      const q = query(
        collection(db, "notifications"),
        where("to_id", "==", setnotificationsTokenIDs),
        where("isRead", "==", false),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result1 = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        // console.log("result");
        console.log(result1);
        setnotifications(result1);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();

    // deleteAllDocumentsAndUsers();
  }, [isUserLoggedin]);

  async function deleteAllDocumentsAndUsers() {
    try {
      // notifications
      // checklist_assign
      // tasks
      //schaudel
      const collectionRef = collection(db, "schaudel"); // Update the collection name

      // Query documents with a specific role
      const querySnapshot = await getDocs(query(collectionRef));

      //query(collectionRef, where("role", "==", "supervisor"))
      // Create an array of delete promises
      const deletePromises = querySnapshot.docs.map(async (doc) => {
        const auth = getAuth();
        // console.log(doc.data().id);
        // await deleteUser(auth, doc.data().id); // Delete user

        // getAuth()
        //   .deleteUser(doc.data().id)
        //   .then(() => {
        //     console.log("Successfully deleted user");
        //   });

        await deleteDoc(doc.ref); // Delete Firestore document
      });

      // Wait for all delete operations to complete
      await Promise.all(deletePromises);

      console.log(
        "All documents and corresponding users deleted successfully!"
      );
    } catch (error) {
      console.error("Error deleting documents:", error);
    }
  }

  return (
    <>
      {/* {console.log(notifications)} */}
      <ToastContainer />
      <AppContext.Provider value={AppContextdata}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={<Navigate replace to={"/admin/login"} />}
            />

            <Route
              path="admin/login/"
              element={
                <Loginprotected>
                  <Login
                    updateToken={updateToken}
                    setIsUserLoggedin={setIsUserLoggedin}
                  />
                </Loginprotected>
              }
            />

            <Route
              path="admin/signup/"
              element={
                <Loginprotected>
                  <Signup updateToken={updateToken} />
                </Loginprotected>
              }
            />
            {/* Super Admin Routes */}
            <Route
              path="/admin"
              element={
                <Privateprotected>
                  <Layout
                    notifications={notifications}
                    department={department}
                  />
                </Privateprotected>
              }
            >
              <Route
                path="notification"
                element={
                  <Privateprotected>
                    <Notifications notifications={notifications} />
                  </Privateprotected>
                }
              />

              <Route
                path="dashboard"
                element={
                  <Privateprotected>
                    <Dashboard />
                  </Privateprotected>
                }
              />

              <Route
                path="users"
                element={
                  <Privateprotected>
                    <User />
                  </Privateprotected>
                }
              />
              <Route
                path="users/user"
                element={
                  <Privateprotected>
                    <Adduser />
                  </Privateprotected>
                }
              />
              <Route
                path="users/edit/:id"
                element={
                  <Privateprotected>
                    <Edituser />
                  </Privateprotected>
                }
              />
              <Route
                path="users/viewuser"
                element={
                  <Privateprotected>
                    <Viewuser />
                  </Privateprotected>
                }
              />

              <Route
                path="catering"
                element={
                  <Privateprotected>
                    <Catering />
                  </Privateprotected>
                }
              />
              <Route
                path="catering/add"
                element={
                  <Privateprotected>
                    <AddCatering />
                  </Privateprotected>
                }
              />
              <Route
                path="catering/edit/:id"
                element={
                  <Privateprotected>
                    <EditCatering />
                  </Privateprotected>
                }
              />

              <Route
                path="managers"
                element={
                  <Privateprotected>
                    <DepartmentManager />
                  </Privateprotected>
                }
              />
              <Route
                path="managers/view/:id"
                element={
                  <Privateprotected>
                    <DepartmentManagerView />
                  </Privateprotected>
                }
              />

              <Route
                path="supervisors"
                element={
                  <Privateprotected>
                    <Supervisor role={getLoggedInuserRole} />
                  </Privateprotected>
                }
              />
              <Route
                path="supervisor/addsupervisor"
                element={
                  <Privateprotected>
                    <Addsupervisor />
                  </Privateprotected>
                }
              />
              <Route
                path="supervisor/editsupervisor"
                element={
                  <Privateprotected>
                    <Editsupervisor />
                  </Privateprotected>
                }
              />
              <Route
                path="supervisors/view/:id"
                element={
                  <Privateprotected>
                    <Viewsupervisor />
                  </Privateprotected>
                }
              />

              <Route
                path="attendance"
                element={
                  <Privateprotected>
                    <Attendance />
                  </Privateprotected>
                }
              />

              <Route
                path="servers"
                element={
                  <Privateprotected>
                    <Server role={getLoggedInuserRole} />
                  </Privateprotected>
                }
              />
              <Route
                path="server/addserver"
                element={
                  <Privateprotected>
                    <Addserver />
                  </Privateprotected>
                }
              />
              <Route
                path="server/editserver"
                element={
                  <Privateprotected>
                    <Editserver />
                  </Privateprotected>
                }
              />
              <Route
                path="servers/view/:id"
                element={
                  <Privateprotected>
                    <Viewserver />
                  </Privateprotected>
                }
              />

              <Route
                path="porters"
                element={
                  <Privateprotected>
                    <PorterMainManager role={getLoggedInuserRole} />
                  </Privateprotected>
                }
              />
              <Route
                path="portermainmanager/addportermain"
                element={
                  <Privateprotected>
                    <AddPorterMain />
                  </Privateprotected>
                }
              />
              <Route
                path="portermainmanager/editporterMain"
                element={
                  <Privateprotected>
                    <EditPorterMain />
                  </Privateprotected>
                }
              />
              <Route
                path="porters/view/:id"
                element={
                  <Privateprotected>
                    <ViewPorterMainManager />
                  </Privateprotected>
                }
              />

              <Route
                path="setting"
                element={
                  <Privateprotected>
                    <Setting />
                  </Privateprotected>
                }
              />
              <Route
                path="profile"
                element={
                  <Privateprotected>
                    <Profile />
                  </Privateprotected>
                }
              />

              <Route
                path="department"
                element={
                  <Privateprotected>
                    <Department />
                  </Privateprotected>
                }
              />
              <Route
                path="department/editdepartment/:id"
                element={
                  <Privateprotected>
                    <Editdepartment />
                  </Privateprotected>
                }
              />
              <Route
                path="department/viewdepartment/:id"
                element={
                  <Privateprotected>
                    <Viewdepartment />
                  </Privateprotected>
                }
              />

              <Route
                path="events"
                element={
                  <Privateprotected>
                    <Event />
                  </Privateprotected>
                }
              />
              <Route
                path="events/add"
                element={
                  <Privateprotected>
                    <Eventadd />
                  </Privateprotected>
                }
              />
              <Route
                path="events/edit/:id"
                element={
                  <Privateprotected>
                    <Eventedit />
                  </Privateprotected>
                }
              />
              <Route
                path="events/view/:id"
                element={
                  <Privateprotected>
                    <Eventview />
                  </Privateprotected>
                }
              />

              <Route
                path="task"
                element={
                  <Privateprotected>
                    <Task />
                  </Privateprotected>
                }
              />
              <Route
                path="task/addtask"
                element={
                  <Privateprotected>
                    <Addtask />
                  </Privateprotected>
                }
              />
              <Route
                path="task/edittask/:id"
                element={
                  <Privateprotected>
                    <EditTask />
                  </Privateprotected>
                }
              />

              <Route
                path="auditorium"
                element={
                  <Privateprotected>
                    <Auditorium />
                  </Privateprotected>
                }
              />
              <Route
                path="auditorium/edit/:id"
                element={
                  <Privateprotected>
                    <EditAuditorium />
                  </Privateprotected>
                }
              />
              <Route
                path="auditorium/add"
                element={
                  <Privateprotected>
                    <AddAuditorium />
                  </Privateprotected>
                }
              />
              <Route
                path="auditorium/viewauditorium"
                element={
                  <Privateprotected>
                    <ViewAuditorium />
                  </Privateprotected>
                }
              />

              <Route
                path="porterteam"
                element={
                  <Privateprotected>
                    <PorterTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="porterteam/edit/:id"
                element={
                  <Privateprotected>
                    <EditPorterTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="porterteam/add"
                element={
                  <Privateprotected>
                    <AddPorterTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="porterteam/viewporterteam"
                element={
                  <Privateprotected>
                    <ViewPorterTeam />
                  </Privateprotected>
                }
              />

              <Route
                path="serverteam"
                element={
                  <Privateprotected>
                    <ServerTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="serverteam/edit/:id"
                element={
                  <Privateprotected>
                    <EditServerTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="serverteam/add"
                element={
                  <Privateprotected>
                    <AddServerTeam />
                  </Privateprotected>
                }
              />
              <Route
                path="serverteam/view"
                element={
                  <Privateprotected>
                    <ViewServerTeam />
                  </Privateprotected>
                }
              />

              <Route
                path="sous-safe"
                element={
                  <Privateprotected>
                    <Chef />
                  </Privateprotected>
                }
              />
              <Route
                path="sous-safe/add"
                element={
                  <Privateprotected>
                    <Addchef />
                  </Privateprotected>
                }
              />
              <Route
                path="sous-safe/edit/:id"
                element={
                  <Privateprotected>
                    <Editchef />
                  </Privateprotected>
                }
              />
              <Route
                path="sous-safe/view/:id"
                element={
                  <Privateprotected>
                    <Viewchef />
                  </Privateprotected>
                }
              />

              <Route
                path="cooks"
                element={
                  <Privateprotected>
                    <Cook />
                  </Privateprotected>
                }
              />
              <Route
                path="cooks/add"
                element={
                  <Privateprotected>
                    <Addcook />
                  </Privateprotected>
                }
              />
              <Route
                path="cooks/edit/:id"
                element={
                  <Privateprotected>
                    <Editcook />
                  </Privateprotected>
                }
              />
              <Route
                path="cooks/view/:id"
                element={
                  <Privateprotected>
                    <Viewcook />
                  </Privateprotected>
                }
              />

              {/*                 
                <Route path='supervisortask' element={<Privateprotected><Supervisortask/></Privateprotected>}/>
                <Route path='supervisortask/supervisortaskresponce' element={<Privateprotected><SupervisorTaskResponce/></Privateprotected>}/>

                <Route path='servermanagertask' element={<ServermanagerTask/>}/>
                <Route path='servermanagertask/servertaskresponce' element={<ServerTaskResponce/>}/> */}

              {/* <Route path='porter' element={<Porter  role={getLoggedInuserRole}  />}/> 
                <Route path='porter/editPorter' element={<EditPorter/>}/>   
                <Route path='porter/addporter' element={<AddPorter/>}/>   
                <Route path='porter/viewporter' element={<ViewPorter/>}/>   */}
            </Route>

            {/* Catering Routes */}
            <Route
              path="/catering"
              element={
                <Privateprotectedcatering>
                  <Layout notifications={notifications} />
                </Privateprotectedcatering>
              }
            >
              <Route
                path="dashboard"
                element={
                  <Privateprotectedcatering>
                    <Dashboard />
                  </Privateprotectedcatering>
                }
              />

              <Route
                path="setting"
                element={
                  <Privateprotectedcatering>
                    <Setting />
                  </Privateprotectedcatering>
                }
              />
              <Route
                path="profile"
                element={
                  <Privateprotectedcatering>
                    <Profile />
                  </Privateprotectedcatering>
                }
              />

              <Route
                path="events"
                element={
                  <Privateprotectedcatering>
                    <Event />
                  </Privateprotectedcatering>
                }
              />
              <Route
                path="events/add"
                element={
                  <Privateprotectedcatering>
                    <Eventadd />
                  </Privateprotectedcatering>
                }
              />
              <Route
                path="events/edit/:id"
                element={
                  <Privateprotectedcatering>
                    <Eventedit />
                  </Privateprotectedcatering>
                }
              />
              <Route
                path="events/view/:id"
                element={
                  <Privateprotectedcatering>
                    <Eventview />
                  </Privateprotectedcatering>
                }
              />
            </Route>

            {/* Department Routes */}
            <Route
              path="/department"
              element={
                <PrivateprotectedDepartment>
                  <Layout
                    notifications={notifications}
                    department={department}
                  />
                </PrivateprotectedDepartment>
              }
            >
              <Route
                path="event/view/:id"
                element={
                  <PrivateprotectedDepartment>
                    <Eventviewglobal />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="porters/schedule/add/:id"
                element={
                  <PrivateprotectedDepartment>
                    <Scheduleadd />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="servers/schedule/add/:id"
                element={
                  <PrivateprotectedDepartment>
                    <Scheduleadd />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="dashboard"
                element={
                  <PrivateprotectedDepartment>
                    <Dashboard />
                  </PrivateprotectedDepartment>
                }
              />

              <Route path="service" element={<Service />} />

              <Route
                path="task"
                element={
                  <PrivateprotectedDepartment>
                    <DepartmentTask />
                  </PrivateprotectedDepartment>
                }
              />
              <Route
                path="task/addtask"
                element={
                  <PrivateprotectedDepartment>
                    <AddDepartmentTask />
                  </PrivateprotectedDepartment>
                }
              />
              <Route
                path="task/edittask/:id"
                element={
                  <PrivateprotectedDepartment>
                    <EditDepartmenttask />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="checklist"
                element={
                  <PrivateprotectedDepartment>
                    <Departmentchecklist />
                  </PrivateprotectedDepartment>
                }
              />

              <Route path="serverteam" element={<ServerTeam />} />
              <Route
                path="serverteam/editserverteam"
                element={<EditServerTeam />}
              />
              <Route
                path="serverteam/addserverteam"
                element={<AddServerTeam />}
              />
              <Route
                path="serverteam/viewserverteam"
                element={<ViewServerTeam />}
              />

              <Route path="supervisors" element={<Supervisor />} />
              <Route path="supervisors/add" element={<Addsupervisor />} />
              <Route path="supervisors/edit/:id" element={<Editsupervisor />} />
              <Route path="supervisors/view/:id" element={<Viewsupervisor />} />

              <Route path="servers" element={<Server />} />
              <Route path="servers/add" element={<Addserver />} />
              <Route path="servers/edit/:id" element={<Editserver />} />
              <Route path="servers/view/:id" element={<Viewserver />} />

              <Route
                path="porters"
                element={<PorterMainManager role={getLoggedInuserRole} />}
              />
              <Route path="porters/add" element={<AddPorterMain />} />
              <Route path="porters/edit/:id" element={<EditPorterMain />} />
              <Route
                path="porters/view/:id"
                element={<ViewPorterMainManager />}
              />

              <Route path="sous-safe" element={<Chef />} />
              <Route path="sous-safe/add" element={<Addchef />} />
              <Route path="sous-safe/edit/:id" element={<Editchef />} />
              <Route path="sous-safe/view/:id" element={<Viewchef />} />

              <Route path="cooks" element={<Cook />} />
              <Route path="cooks/add" element={<Addcook />} />
              <Route path="cooks/edit/:id" element={<Editcook />} />
              <Route path="cooks/view/:id" element={<Viewcook />} />

              <Route
                path="notification"
                element={
                  <PrivateprotectedDepartment>
                    <Notifications notifications={notifications} />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="profile"
                element={
                  <PrivateprotectedDepartment>
                    <Profile />
                  </PrivateprotectedDepartment>
                }
              />
            </Route>

            {/* Supervisor Routes */}
            <Route
              path="/supervisor"
              element={
                <Privateprotectedsupervisor>
                  <Layout notifications={notifications} />
                </Privateprotectedsupervisor>
              }
            >
              <Route
                path="dashboard"
                element={
                  <Privateprotectedsupervisor>
                    <Dashboard />
                  </Privateprotectedsupervisor>
                }
              />

              <Route
                path="task"
                element={
                  <Privateprotectedsupervisor>
                    <DepartmentTask />
                    {/* <Taskportal /> */}
                  </Privateprotectedsupervisor>
                }
              />
              <Route
                path="task/addtask"
                element={
                  <PrivateprotectedDepartment>
                    <AddDepartmentTask />
                  </PrivateprotectedDepartment>
                }
              />
              <Route
                path="task/edittask/:id"
                element={
                  <PrivateprotectedDepartment>
                    <EditDepartmenttask />
                  </PrivateprotectedDepartment>
                }
              />

              <Route
                path="notification"
                element={
                  <Privateprotectedsupervisor>
                    <Notifications notifications={notifications} />
                  </Privateprotectedsupervisor>
                }
              />

              <Route
                path="profile"
                element={
                  <Privateprotectedsupervisor>
                    <Profile />
                  </Privateprotectedsupervisor>
                }
              />
            </Route>

            {/* Server Routes */}
            <Route
              path="/server"
              element={
                <Privateprotectedserver>
                  <Layout notifications={notifications} />
                </Privateprotectedserver>
              }
            >
              <Route
                path="dashboard"
                element={
                  <Privateprotectedserver>
                    <Dashboard />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="checklist"
                element={
                  <Privateprotectedserver>
                    <Checklist />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="checklist/view/:id"
                element={
                  <Privateprotectedserver>
                    <Checklistview />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="event/view/:id"
                element={
                  <Privateprotectedserver>
                    <Eventviewglobal />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="task"
                element={
                  <Privateprotectedserver>
                    <Taskportal />
                  </Privateprotectedserver>
                }
              />
              <Route
                path="task/addtask"
                element={
                  <Privateprotectedserver>
                    <AddDepartmentTask />
                  </Privateprotectedserver>
                }
              />
              <Route
                path="task/edittask/:id"
                element={
                  <Privateprotectedserver>
                    <EditDepartmenttask />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="notification"
                element={
                  <Privateprotectedserver>
                    <Notifications notifications={notifications} />
                  </Privateprotectedserver>
                }
              />

              <Route
                path="profile"
                element={
                  <Privateprotectedserver>
                    <Profile />
                  </Privateprotectedserver>
                }
              />
            </Route>

            {/* Porter Routes */}
            <Route
              path="/porter"
              element={
                <Privateprotectedporter>
                  <Layout notifications={notifications} />
                </Privateprotectedporter>
              }
            >
              <Route
                path="dashboard"
                element={
                  <Privateprotectedporter>
                    <Dashboard />
                  </Privateprotectedporter>
                }
              />

              <Route
                path="task/event/view/:id"
                element={
                  <Privateprotectedporter>
                    <Eventviewglobal />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="task"
                element={
                  <Privateprotectedporter>
                    <Taskportal />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="task/addtask"
                element={
                  <Privateprotectedporter>
                    <AddDepartmentTask />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="task/edittask/:id"
                element={
                  <Privateprotectedporter>
                    <EditDepartmenttask />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="checklist"
                element={
                  <Privateprotectedporter>
                    <Checklist />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="checklist/view/:id"
                element={
                  <Privateprotectedporter>
                    <Checklistview />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="notification"
                element={
                  <Privateprotectedporter>
                    <Notifications notifications={notifications} />
                  </Privateprotectedporter>
                }
              />
              <Route
                path="profile"
                element={
                  <Privateprotectedporter>
                    <Profile />
                  </Privateprotectedporter>
                }
              />
            </Route>
          </Routes>
        </Router>
      </AppContext.Provider>
    </>
  );
}

export default App;
export { AppContext };
