import React from 'react'
import { Link } from 'react-router-dom'
import logo2 from '../../../assest/admin_assest/img/logo/logo2.png';
const Footer = () => {
    return (
        <>
            <footer className="content-footer footer bg-footer-theme bg-light">
                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                        <Link to="/"  className="footer-link fw-bolder"><img src={logo2} className='footer_logo_img'/></Link>
                    </div>
                    {/* <div>
                        <Link to="/" className="footer-link me-4" target="_blank">License</Link>
                        <Link to="/" target="_blank" className="footer-link me-4">More Themes</Link>
                        <Link  to="/" target="_blank"  className="footer-link me-4">Documentation</Link>
                        <Link to="/" target="_blank" className="footer-link me-4">Support</Link>
                    </div> */}
                </div>
            </footer>
        </>
    )
}

export default Footer;
