import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Moment from "moment";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getDocs,
  get,
  collection,
  onSnapshot,
  query,
  doc,
  where,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
  deparmentUsers,
  eventcollection,
  taskcollection,
} from "../../../FirebaseConfig";
import { changeDateFotmate } from "../../../functions/Function";

const Task = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [joinedData, setJoinedData] = useState([]);
  const [data, setData] = useState([]);

  const handleClick = (id, row) => {
    navigate("/edittask/" + id, { state: row });
  };

  useEffect(() => {
    // Fetch tasks collection
    const fetchTasks = async () => {
      const q = query(collection(db, taskcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setTasks(result);
        performLeftJoin();
      });
    };

    // Fetch event collection
    const fetchEvents = async () => {
      const q = query(collection(db, eventcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setEvents(result);
        performLeftJoin();
      });
    };

    // Fetch users collection
    const fetchUsers = async () => {
      const q = query(collection(db, usercollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
        performLeftJoin();
      });
    };

    // Fetch departments collection
    const fetchDepartments = async () => {
      const q = query(collection(db, deparmentcollection));
      const unsubscribe1 = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({ ...doc.data() }));
        setDepartments(result);
        performLeftJoin();
      });
    };
    fetchTasks();
    fetchUsers();
    fetchEvents();
    fetchDepartments();
  }, []);

  useEffect(() => {
    performLeftJoin();
  }, [users, departments, tasks]);

  // Perform left join operation
  const performLeftJoin = () => {
    const joinedDataWithUsersAndDepartments = tasks
      .map((join) => {
        const department = departments.find(
          (dept) => dept.id == join.department_id
        );
        const from_user = users.find((user) => user.docid == join.from_id);
        const to_user = users.find((user) => user.docid == join.to_id);
        const eventdata = events.find((event) => event.docid == join.event_id);
        //console.log(from_user)

        return {
          docid: join.docid,
          ...join,
          department: department,
          from_user: from_user,
          to_user: to_user,
          event: eventdata,
        };
      })
      .filter((element) => element.department !== undefined);
    setData(joinedDataWithUsersAndDepartments);
  };
  return (
    <>
      {console.log(data)}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Tasks</h5>

                {/* <Link to="addtask" className='user_list_add'>
                                    <button type="button" className="btn btn-primary add_user_btn_bar">
                                        <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Task
                                    </button>
                                </Link> */}
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive custom">
                  <table className="table table-md table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>SR.No</th>

                        <th className="wmin-700">
                          <div className="w-150s">Task Details</div>
                        </th>

                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {data.length > 0 ? (
                        data.map((row, i) => (
                          <tr
                            key={row.docid}
                            className={`${
                              row.status == "completed"
                                ? "taskcompletedrow"
                                : "taskpendingrow"
                            }`}
                          >
                            <td>{i + 1}</td>

                            <td>
                              <div className="fontbold">
                                {row?.to_user?.name} &nbsp;
                                <span className="badge bg-label-dark me-1">
                                  {row?.to_user?.role}
                                </span>
                              </div>
                              <div className="fontbold">
                                <small>
                                  Department :{" "}
                                  {row?.department?.department_name}
                                </small>
                              </div>

                              <div className="fontbold">
                                <small>Event : {row?.event?.name}</small>
                              </div>

                              <div>
                                {" "}
                                <small>{row?.task}</small>
                              </div>
                              <div>
                                <span className="badge bg-label-dark me-1">
                                  <small>
                                    {Moment(
                                      row?.created_date,
                                      "DD MM YYYY"
                                    ).format("DD/MM/YYYY")}{" "}
                                    <i>
                                      Start{" "}
                                      {Moment(row?.start_time, "HH:mm").format(
                                        "hh:mm A"
                                      )}
                                    </i>{" "}
                                    -{" "}
                                    <i>
                                      End{" "}
                                      {Moment(row?.end_time, "HH:mm").format(
                                        "hh:mm A"
                                      )}
                                    </i>
                                  </small>
                                </span>
                              </div>
                            </td>

                            <td>
                              <span
                                class={`badge ${
                                  row?.status == "completed"
                                    ? "bg-label-success"
                                    : "bg-label-danger"
                                }  me-1`}
                              >
                                {row?.status}
                              </span>
                            </td>

                            <td>
                              <div>
                                <Link
                                  to={`edittask/${row.docid}`}
                                  className="Edit_user_user_list"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-primary ball_icon"
                                  >
                                    <span className="tf-icons fa fa-pencil-square"></span>
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <p className="text-center m-2"> Data Not Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
