import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = (props) => {
  const [click, SetClick] = useState(false);

  const handleClick = () => SetClick(!click);

  return (
    <>
      <div
        className={`layout-wrapper layout-content-navbar ${
          click ? "layout-menu-expanded" : "null"
        }`}
      >
        <div className="layout-container">
          <Sidebar handleClick={handleClick} />

          <div className="layout-page">
            <Header
              handleClick={handleClick}
              notifications={props.notifications}
              department={props.department}
            />

            <div className="content-wrapper">
              <Outlet />
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
};

export default Layout;
