import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import usericon from "../../../assest/admin_assest/img/user.png";
import {
  getDocs,
  get,
  updateDoc,
  collection,
  onSnapshot,
  query,
  doc,
  where,
  getDoc,
} from "firebase/firestore";
import {
  db,
  usercollection,
  deparmentcollection,
  deparmentUsers,
  eventcollection,
  porterchecklistcollection,
  checklistassigncollection,
} from "../../../FirebaseConfig";

import {
  changeDateFotmate,
  loggedInUserDetails,
  successToast,
  errorToast,
} from "../../../functions/Function";

const Checklistview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [checklistAssigned, setchecklistAssigned] = useState({});
  const [data, setData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckboxChange = (itemId) => {
    const updatedItems = data.map((item) =>
      item.id === itemId ? { ...item, checked: !item.checked } : item
    );
    setData(updatedItems);
  };

  const handleCheckAllChange = () => {
    const updatedItems = data.map((item) => ({ ...item, checked: !checkAll }));
    setData(updatedItems);
    setCheckAll(!checkAll);
  };

  const onSubmit = async (data) => {
    const docRef = doc(db, checklistassigncollection, params.id);
    const updateData = {
      status: "completed",
    };

    updateDoc(docRef, updateData)
      .then((docRef) => {
        successToast("Congratulations! Checklist updated successfully");
        navigate(-1);
      })
      .catch((error) => {
        errorToast("Opps somthing wrong!");
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchAssignedData = async () => {
      try {
        const docRef = doc(db, checklistassigncollection, params.id);
        const docSnap = await getDoc(docRef);
        const row = docSnap.data();
        setchecklistAssigned(row);

        // if (row.status == "completed") {
        //   const updatedData = data.map((doc) =>
        //     doc.status == "completed" ? { ...doc, checked: true } : doc
        //   );
        //   setData(updatedData);
        // }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAssignedData();

    const fetchData = async () => {
      const q = query(collection(db, porterchecklistcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          checked: false,
          ...doc.data(),
        }));
        setData(result);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);
  return (
    <>
      {/* {console.log(data)} */}
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="user_name_add">
                <h5 className="card-header card_hed_user">Checklist List</h5>

                {/* <Link to="adduser" className='user_list_add'>
                                    <button type="button" className="btn btn-primary add_user_btn_bar">
                                        <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add User
                                    </button>
                                </Link> */}
              </div>
              {checklistAssigned?.status == "completed" && (
                <h5 className="text-center">
                  You have completed the checklist
                </h5>
              )}
              {checklistAssigned?.status != "completed" && (
                <div className="card-body pt-0">
                  <div className="col-12 mb-4">
                    <div className="d-inline-block bg-primary p-2 text-dark">
                      <div className="form-check cursor-pointer">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          checked={checkAll}
                          onChange={handleCheckAllChange}
                          id="checklist"
                        />{" "}
                        <label
                          className="form-check-label cursor-pointer"
                          htmlFor={`checklist`}
                        >
                          Check All
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {data.length > 0 &&
                      data.map((row, i) => (
                        <div className="col-12 mb-4">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={row.checked}
                              value=""
                              id={`checklist${i}`}
                              onChange={() => handleCheckboxChange(row.id)}
                            />{" "}
                            How check all checkbox checked in list in react
                            <label
                              className="form-check-label"
                              htmlFor={`checklist${i}`}
                            >
                              {row.title}
                            </label>
                          </div>
                        </div>
                      ))}

                    <div className="mt-4 text-center">
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={onSubmit}
                        disabled={checkAll == false ? true : false}
                      >
                        {" "}
                        {disabled == false
                          ? " Complete Checklist"
                          : "Updating..."}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checklistview;
