import React from 'react'
import { Link } from 'react-router-dom';
const Viewuser = () => {
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="col-lg-12 mb-4 order-0">
                        <div className="card">
                            <div className='user_name_add'>
                                <h5 className="card-header">User Information</h5>

                                <Link to="/admin/user" className='user_list_add'>
                                    <button type="button" class="btn btn-primary add_user_btn_bar">
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Back
                                    </button>
                                </Link>
                            </div>
                            <div class="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <td>Albert Cook</td>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        <tr>
                                            <th>EMAIL</th>
                                            <td>AlbertCook@gmail.com</td>
                                        </tr>
                                        <tr>
                                            <th>PHONE NO.</th>
                                            <td>9876543218</td>
                                        </tr>

                                        <tr>
                                            <th>Department</th>
                                            <td>F&B Manager & Kitchen</td>
                                        </tr>
                                        <tr>
                                            <th>Role</th>
                                            <td><span class="badge bg-label-info me-1">manager</span></td>
                                        </tr>

                                        <tr>
                                            <th>Status</th>
                                            <td><span class="badge bg-label-success me-1">Active</span></td>
                                        </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Viewuser;
