import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Schedule from "./Schedule";
import Eventmodel from "./Eventmodel";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInUserDetails,
} from "../../../functions/Function";
import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  doc,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  app,
  db,
  auth,
  usercollection,
  eventcollection,
  taskcollection,
} from "../../../FirebaseConfig";
import {
  AddEventTypeNotification,
  createNotification,
} from "../../../functions/Notification";
import { useForm } from "react-hook-form";
import { Watch } from "react-loader-spinner";

const AddDepartmentTask = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [EventmodalShow, setEventmodalShow] = useState(false);

  const [selectedUser, setselectedUser] = useState("");
  const [selectedEvent, setselectedEvent] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [checklistAssigned, setchecklistAssigned] = useState({});
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      department_id: loggedInUserDetails("department_id"),
      event_id: "",
      from_id: loggedInUserDetails("docid"),
      to_id: "",
      task: "",
      start_time: "",
      end_time: "",
      status: "pending",
      created_date: Moment().format("L, h:mm:ss a"),
      updated_date: Moment().format("L, h:mm:ss a"),
      timestamp: serverTimestamp(),
    },
  });

  const onErrors = (errors) => {
    const errorLength = Object.keys(errors).length;
    if (errorLength > 0) {
      errorToast(formValidtionsMessages(errors), "top-right", "8000");
    }
  };
  const onSubmit = async (inputdata) => {
    try {
      const eventData = data.find((item) => item.id === inputdata.event_id);
      addDoc(collection(db, taskcollection), inputdata).then((doc) => {
        const notificationData = {
          to_id: inputdata.to_id,
          type: "task",
          action: "create_task",
          taskName: inputdata.task,
          event_name: eventData.name,
          task_id: doc.id,
        };

        //AddEventTypeNotification(notificationData);
        createNotification(notificationData).then(() => {});
        addDoc(collection(db, "checklist_assign"), checklistAssigned).then(
          () => {
            successToast("Task created successfully", "top-right", "3000");
            navigate(-1);
          }
        );
      });
    } catch (error) {
      console.error("Error adding document:", error.message);
    }
  };

  const handleCheckboxChange = async (e) => {
    const eventid = watch("event_id"); // You should have the watch function from your form library
    if (eventid === "") {
      errorToast("Please select an event first", "top-right", 3000);
      return false;
    }

    const selectedUserID = watch("to_id"); // You should have the watch function from your form library
    if (selectedUserID === "") {
      errorToast("Please select a user first", "top-right", 3000);
      return false;
    }

    try {
      const q = query(
        collection(db, "checklist_assign"),
        where("event_id", "==", eventid)
      );
      let alredyassign = false;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        alredyassign = true;
      });

      if (alredyassign === true) {
        errorToast(
          "Checklist is already assigned to this event, you cannot reassign it",
          "top-right",
          3000
        );
        return false;
      }

      setIsChecked(!isChecked);
      setchecklistAssigned({
        event_id: eventid,
        to_id: selectedUserID,
        department_id: loggedInUserDetails("department_id"),
        status: "pending",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validateEndTime = (endTime) => {
    const startTime = watch("start_time");
    if (startTime && endTime <= startTime) {
      return "End time must be greater than start time";
    }
    return true;
  };
  const Modelshow = (id) => {
    if (watch("to_id") == "") {
      errorToast("Please select user first", "top-right", "8000");
      return false;
    }
    setselectedUser(id);
    setModalShow(true);
  };

  const EventModelshow = (id) => {
    if (watch("event_id") == "") {
      errorToast("Please select event first", "top-right", "8000");
      return false;
    }
    setselectedEvent(id);
    setEventmodalShow(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, eventcollection));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(result);
      });
      return () => {
        unsubscribe();
      };
    };
    const fetchUsers = async () => {
      const q = query(
        collection(db, usercollection),
        where("department_id", "==", loggedInUserDetails("department_id")),
        where("role", "in", ["porter", "server"])
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
      });
      return () => {
        unsubscribe();
      };
    };
    fetchUsers();
    fetchData();
  }, []);

  return (
    <>
      <Schedule
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={selectedUser}
      />

      <Eventmodel
        show={EventmodalShow}
        onHide={() => setEventmodalShow(false)}
        id={selectedEvent}
      />

      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="user_name_add">
                  <h5 className="card-header card_hed_user">Task Details</h5>
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-primary add_user_btn_bar"
                  >
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    &nbsp;Back
                  </button>
                </div>

                <form
                  method="post"
                  onSubmit={handleSubmit(onSubmit, onErrors)}
                  autocomplete="off"
                >
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <label for="largeSelect" className="form-label">
                          Event List
                        </label>

                        <div className="d-flex customflex">
                          <select
                            {...register("event_id", {
                              required: "Event is required",
                            })}
                            name="event_id"
                            className="form-select form-select-lg form_selected_for_custom"
                          >
                            <option value="">Event Select</option>
                            {data.length > 0 &&
                              data.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                          </select>
                          <button
                            className="btn btn-primary"
                            onClick={() => EventModelshow(watch("event_id"))}
                            type="button"
                          >
                            View Event Details
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="">
                          <label for="largeSelect" className="form-label">
                            Select User
                          </label>
                          <div className="d-flex customflex">
                            <select
                              {...register("to_id", {
                                required: "User is required",
                              })}
                              name="to_id"
                              className="form-select form-select-lg form_selected_for_custom  w-70"
                            >
                              <option value="">Select User</option>
                              {users.length > 0 &&
                                users.map((option) => (
                                  <option value={option.docid}>
                                    {option.name} -- {option.role}
                                  </option>
                                ))}
                            </select>
                            <button
                              className="btn btn-primary"
                              onClick={() => Modelshow(watch("to_id"))}
                              type="button"
                            >
                              View Schedule
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-4">
                        <label className="form-label">Start Time</label>

                        <input
                          type="time"
                          name="start_time"
                          {...register("start_time", {
                            required: "Start time is required",
                          })}
                          className="form-control"
                        />
                      </div>

                      <div className="col-lg-6 mt-4">
                        <label className="form-label">End Time</label>

                        <input
                          type="time"
                          name="end_time"
                          {...register("end_time", {
                            required: "End time is required",
                            validate: validateEndTime,
                          })}
                          className="form-control"
                        />
                      </div>

                      <div className="col-lg-12 mt-4">
                        <label className="form-label">Task</label>
                        <textarea
                          name="task"
                          {...register("task", {
                            required: "Task is required",
                          })}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>

                      <div className="col-lg-12 mt-4">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="checklist"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label class="form-check-label" for="checklist">
                            Checking the checkbox below will assign the
                            checklist to the selected porter or server
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-12 mt-4">
                        <div className="">
                          <label for="largeSelect" className="form-label">
                            Status
                          </label>

                          <select
                            {...register("status", {
                              required: "Status is required",
                            })}
                            name="status"
                            className="form-select form-select-lg form_selected_for_custom"
                          >
                            <option value="">Select Status</option>
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        className="btn btn-primary me-2"
                        type="submit"
                        disabled={disabled}
                      >
                        {" "}
                        {disabled == false ? "Save" : "Saving..."}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDepartmentTask;
