import React, { useState } from "react";
import logo1 from "../../../assest/admin_assest/img/logo/logo.jpeg";
import { NavLink, Link } from "react-router-dom";
import {
  getLoggedInuserRole,
  getLoggedInuserDepatmentID,
  loggedInUserDetails,
} from "../../../functions/Function";
import { HiUsers } from "react-icons/hi";
import { RiUserSettingsFill } from "react-icons/ri";
import { ImUsers } from "react-icons/im";
import { BiLayer } from "react-icons/bi";

const Sidebar = (props) => {
  const [click, SetClick] = useState(false);
  const toggleClick = () => SetClick(!click);
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <NavLink to="dashboard" className="app-brand-link">
            <span className="app-brand-logo demo"></span>
            <span className="app-brand-text demo menu-text fw-bolder ms-2">
              <img src={logo1} className="logo_img_for_side_bar" />
            </span>
          </NavLink>

          <Link
            onClick={() => props.handleClick()}
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="fa fa-chevron-left bx-sm align-middle faa_chirvon_top"></i>
          </Link>
        </div>

        <div className="menu-inner-shadow"></div>
        <ul className="menu-inner py-1">
          {getLoggedInuserRole() === "administrator" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-house-user  menu-icon"></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="users"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-users menu-icon"></i>
                  <div data-i18n="Analytics">Users</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="catering"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-users-viewfinder menu-icon"></i>
                  <div data-i18n="Analytics">Catering Manager</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="supervisors"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-users-line menu-icon"></i>
                  <div data-i18n="Analytics">Supervisors</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="servers"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-circle-user menu-icon"></i>
                  <div data-i18n="Analytics">Servers</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="porters"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-universal-access menu-icon"></i>
                  <div data-i18n="Analytics">Porter</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="sous-safe"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-chess-queen menu-icon"></i>
                  <div data-i18n="Analytics">Sous Chef</div>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="cooks"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-fire-burner menu-icon"></i>
                  <div data-i18n="Analytics">Cooks</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="managers"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-people-group menu-icon"></i>
                  <div data-i18n="Analytics">Department Manager</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="department"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-table-cells-large menu-icon "></i>
                  <div data-i18n="Analytics">Deparments</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="events"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-calendar-days menu-icon"></i>
                  <div data-i18n="Analytics">Events</div>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="attendance"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-clipboard-user menu-icon"></i>
                  <div data-i18n="Analytics">Attendance</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="task"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i className="fa-solid fa-list-check menu-icon"></i>
                  <div data-i18n="Analytics">Task Manager</div>
                </NavLink>
              </li>

              <li
                className={`menu-item ${click ? "open active" : "null"}`}
                onClick={toggleClick}
              >
                <Link className={`menu-link ${click ? "" : ""}`}>
                  <i className="fa-solid fa-square-check  menu-icon"></i>
                  <div className="Account Settings account_bar">
                    <span>Checklists</span>
                    <i
                      className={`menu-icon tf-icons ${
                        click ? "fa fa-angle-down" : "fa fa-angle-right"
                      }`}
                      aria-hidden="true"
                    ></i>
                  </div>
                </Link>
                {click ? (
                  <ul className="menu-sub menu_class_bar">
                    <li
                      className={`menu-item ${click ? "null" : "null"}`}
                      onClick={() => toggleClick()}
                    >
                      <NavLink to="porterteam" className="menu-link">
                        <div>Porter Team Checklist</div>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        to="auditorium"
                        className="menu-link"
                        onClick={() => props.handleClick()}
                      >
                        <div data-i18n="Notifications">
                          Auditorium Checklist
                        </div>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        to="serverteam"
                        className="menu-link"
                        onClick={() => props.handleClick()}
                      >
                        <div data-i18n="Connections">Server Team Checklist</div>
                      </NavLink>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            </>
          )}

          {getLoggedInuserRole() === "catering" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-home menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="events"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-calendar-o menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Events</div>
                </NavLink>
              </li>
            </>
          )}

          {getLoggedInuserRole() == "manager" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-home menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="service"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-gg-circle menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Service</div>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="task"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-tasks menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Tasks</div>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="checklist"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa-solid fa-list-check menu-icon"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Checklist</div>
                </NavLink>
              </li>

              {loggedInUserDetails("department_id") == "1001" && (
                <>
                  <li className="menu-item">
                    <NavLink
                      to="supervisors"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-users-line menu-icon"></i>
                      <div data-i18n="Analytics">Supervisor Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="servers"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-circle-user menu-icon"></i>
                      <div data-i18n="Analytics">Server Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="porters"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-universal-access menu-icon"></i>
                      <div data-i18n="Analytics">Porter Manager</div>
                    </NavLink>
                  </li>
                </>
              )}

              {loggedInUserDetails("department_id") == "1002" && (
                <>
                  <li className="menu-item">
                    <NavLink
                      to="supervisors"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-users-line menu-icon"></i>
                      <div data-i18n="Analytics">Supervisor Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="servers"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-circle-user menu-icon"></i>
                      <div data-i18n="Analytics">Server Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="porters"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-universal-access menu-icon"></i>
                      <div data-i18n="Analytics">Porter Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="sous-safe"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-chess-queen menu-icon"></i>
                      <div data-i18n="Analytics">Sous Chef</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="cooks"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-fire-burner menu-icon"></i>
                      <div data-i18n="Analytics">Cooks</div>
                    </NavLink>
                  </li>
                </>
              )}
              {loggedInUserDetails("department_id") == "1005" && (
                <>
                  <li className="menu-item">
                    <NavLink
                      to="supervisors"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-users-line menu-icon"></i>
                      <div data-i18n="Analytics">Supervisor Manager</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="servers"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-circle-user menu-icon"></i>
                      <div data-i18n="Analytics">Server Manager</div>
                    </NavLink>
                  </li>
                </>
              )}
              {loggedInUserDetails("department_id") == "1004" && (
                <>
                  <li className="menu-item">
                    <NavLink
                      to="sous-safe"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-chess-queen menu-icon"></i>
                      <div data-i18n="Analytics">Sous Chef</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="cooks"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-fire-burner menu-icon"></i>
                      <div data-i18n="Analytics">Cooks</div>
                    </NavLink>
                  </li>
                </>
              )}
              {loggedInUserDetails("department_id") == "1003" && (
                <>
                  <li className="menu-item">
                    <NavLink
                      to="sous-safe"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-chess-queen menu-icon"></i>
                      <div data-i18n="Analytics">Sous Chef</div>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      to="cooks"
                      className="menu-link"
                      onClick={() => props.handleClick()}
                    >
                      <i className="fa-solid fa-fire-burner menu-icon"></i>
                      <div data-i18n="Analytics">Cooks</div>
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}

          {getLoggedInuserRole() === "supervisor" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-home menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="task"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-tasks menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Task</div>
                </NavLink>
              </li>
            </>
          )}

          {getLoggedInuserRole() === "server" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-home menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="task"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-tasks menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Task</div>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="checklist"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa-solid fa-list-check menu-icon"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Checklist</div>
                </NavLink>
              </li>
            </>
          )}

          {getLoggedInuserRole() === "porter" && (
            <>
              <li className="menu-item">
                <NavLink
                  to="dashboard"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-home menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">Dashboard</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="task"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa fa-tasks menu-icon tf-icons"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Task</div>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  to="checklist"
                  className="menu-link"
                  onClick={() => props.handleClick()}
                >
                  <i
                    className="fa-solid fa-list-check menu-icon"
                    aria-hidden="true"
                  ></i>
                  <div data-i18n="Analytics">My Checklist</div>
                </NavLink>
              </li>
            </>
          )}

          {getLoggedInuserRole() === "administrator" && (
            <li className="menu-item">
              <NavLink
                to="setting"
                className="menu-link"
                onClick={() => props.handleClick()}
              >
                <i className="fa-solid fa-gears menu-icon"></i>
                <div data-i18n="Analytics">Settings</div>
              </NavLink>
            </li>
          )}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
