import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import {
  getFirestore,
  addDoc,
  serverTimestamp,
  collection,
  doc,
  where,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  successToast,
  errorToast,
  apibaseURl,
  getvalidationErr,
  formValidtionsMessages,
  loggedInCustomerDetails,
  MyThreeDots,
} from "../../../functions/Function";
import {
  app,
  db,
  auth,
  usercollection,
  schaudelcollection,
} from "../../../FirebaseConfig";

const Scheduleaddmodel = (props) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weekDates, setweekDates] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const generateWeekDates = () => {
    if (!selectedWeek) {
      return null;
    }

    const [year, week] = selectedWeek.split("-");
    const [week1, weeknumber] = selectedWeek.split("W");
    const startDate = new Date(year, 0); // January 1st of the selected year
    const daysToAdd = (parseInt(weeknumber, 10) - 1) * 7; // Calculate days to add

    startDate.setDate(startDate.getDate() + daysToAdd);

    const weekDates = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(currentDate.getDate() + i);
      weekDates.push({
        date: currentDate,
        from_time: "10:00",
        end_time: "23:00",
      });
    }

    setweekDates(weekDates);

    // return weekDates;
  };

  const updateEventTime = (index, property, value) => {
    const updatedEvents = [...weekDates];
    updatedEvents[index][property] = value;
    setweekDates(updatedEvents);
  };

  const deleteRow = (index) => {
    const updatedEvents = weekDates.filter((_, i) => i !== index);
    setweekDates(updatedEvents);
  };

  // Function to validate each event and return error messages
  const validateEvents = () => {
    const errors = [];
    weekDates.forEach((event, index) => {
      if (!event.from_time) {
        errors.push(`Row ${index + 1}: from time is empty`);
      }
      if (!event.end_time) {
        errors.push(`Row ${index + 1}: to time is empty`);
      }
    });
    return errors;
  };

  // Function to handle the submit button click
  const handleSubmit = () => {
    const errors = validateEvents();
    if (errors.length > 0) {
      let collectAllValidationErr = [];
      errors.forEach((error, index) => {
        collectAllValidationErr.push(<div key={index}>{error}</div>);
      });
      const storeAllValidtionErr = <>{collectAllValidationErr}</>;
      errorToast(storeAllValidtionErr, "top-right", "8000");
      setShowValidation(true);
      setValidationMessages(errors);
    } else {
      setShowValidation(false);
      setValidationMessages([]);

      const newData = weekDates.map((item) => {
        const row = {
          date: item.date,
          start_time: item.from_time,
          end_time: item.end_time,
          department_id: loggedInCustomerDetails("department_id"), // You can set the department_id here
          user_id: props.id,
        }; // You can set the user_id here
        return addDoc(collection(db, schaudelcollection), row);
      });
      Promise.all(newData)
        .then(() => {
          props.onHide();
          successToast("Congratulations! schedule added successfully");
        })
        .catch((error) => {
          // Handle any errors that may occur during the promises
          console.error("Error adding schedules:", error);
          // Optionally, you can show an error message here if needed
        });
    }
  };

  useEffect(() => {
    setSelectedWeek("");
    setweekDates([]);
  }, [props.onHide]);

  useEffect(() => {
    generateWeekDates();
  }, [selectedWeek]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Schedule
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {loader === true && <MyThreeDots />} */}
        {console.log(weekDates)}
        <div className="row">
          <div className="col-md-12">
            <div>
              <label className="form-label">Select Week</label>
              <input
                type="week"
                className="form-control"
                name="week"
                placeholder="Enter Name"
                onChange={handleWeekChange}
                value={selectedWeek}
              />
            </div>
          </div>
        </div>
        {weekDates.length > 0 && (
          <table className="table table-md table-bordered mt-4">
            <thead>
              <tr>
                <th>S.NO.</th>
                <th>Date</th>
                <th>From Time</th>
                <th>To Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {weekDates.map((date, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{date.date.toDateString()}</td>
                  <td>
                    <input
                      type="time"
                      className="form-control"
                      value={date.from_time}
                      onChange={(e) =>
                        updateEventTime(index, "from_time", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="time"
                      className="form-control"
                      value={date.end_time}
                      onChange={(e) =>
                        updateEventTime(index, "end_time", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    {/* <button className="btn btn-primary ml-2">Update</button> */}
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => deleteRow(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>

      <Modal.Footer>
        {weekDates.length > 0 && (
          <Button type="button" onClick={handleSubmit}>
            Save
          </Button>
        )}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Scheduleaddmodel;
